import { motion } from "framer-motion";
import React, { useCallback, useMemo } from "react";

import { Button, CollectibleCard, Spinner } from "@/components";
import { ALPHA_DISCOUNT, BUYING_MATCHING_FEE } from "@/constants";
import { useInfoModal } from "@/hooks";
import { ModalInfo } from "@/modals/modalInfo";
import { CollectionListingProps, Currency } from "@/types";

import {
  AnimatedContent,
  ExpandableFeeList,
  FeeListItem,
  LegalFooter,
} from "../../base";
import { calculateTokenValueInDoge, calculateTokenValueInUSD } from "@/utility";

interface Fees {
  network: string;
  taker: string;
  takerDiscountAlpha: string;
  total: string;
}

interface Values {
  valueInUSD?: string;
  value: string;
  valueDetails?: string;
}

interface BuyViewProps {
  handleBuy: () => void;
  loading: boolean;
  fees: Fees;
  pay: Values;
  offers?: CollectionListingProps[];
  canPay?: boolean;
  onRemoveItem?: (offerId: string) => void;
}

export const BuyView: React.FC<BuyViewProps> = ({
  fees,
  pay,
  loading,
  handleBuy,
  offers,
  canPay,
  onRemoveItem,
}: BuyViewProps) => {
  const {
    FeeType,
    info,
    infoVisible,
    handleOpenInfo,
    handleOpenInfoFee,
    handleCloseInfo,
  } = useInfoModal();

  const canRemove = useMemo(
    () => (offers?.length ? (offers?.length > 1 ? true : false) : false),
    [offers],
  );

  const handleOpenPaymentInfo = useCallback(() => {
    handleOpenInfo("Payment Info", [
      "The amounts displayed here are estimates based on your current inscription selections.",
      "The amount You Pay and You Receive therefore may vary from what is shown, contingent on the remaining availability of these inscriptions, the Dogecoin mempool, status of the network, etc.",
      "By proceeding, you agree to these terms.",
    ]);
  }, [handleOpenInfo]);

  return (
    <AnimatedContent id="buy" className="p-0">
      <div className="relative flex max-h-screen flex-1 flex-col overflow-y-scroll px-4 pb-48 pt-2">
        <div className="flex flex-1 flex-col space-y-2">
          {offers &&
            offers.length > 0 &&
            offers.map((listing: CollectionListingProps) => {
              return (
                <CollectibleCard
                  image={listing.imageURI}
                  itemName={listing.name}
                  collectionName={listing.collectionName}
                  valueUSD={`${Currency.USD}${calculateTokenValueInUSD(1, listing.currentDogePrice, listing.price)}`}
                  valueDOGE={`${Currency.DOGE}${calculateTokenValueInDoge(1, listing.price)}`}
                  onRemove={() => canRemove && onRemoveItem?.(listing.offerId)}
                  key={listing.inscriptionId}
                />
              );
            })}
        </div>

        <motion.div className="fixed bottom-0 left-0 flex w-full flex-col space-y-2 border-t border-border-primary bg-background-primary/80 px-4 pt-2 drop-shadow-xl backdrop-blur-xl pb-safe-offset-2">
          {/** List */}
          <motion.div
            layout="position"
            transition={{ duration: 0.1 }}
            className="flex flex-col space-y-1 px-1"
          >
            <FeeListItem
              label="You Pay"
              value={`${pay.valueInUSD} ≈ ${pay.value}`}
              onClick={handleOpenPaymentInfo}
            />

            <ExpandableFeeList
              fees={[
                {
                  label: "Network Fee",
                  value: fees.network,
                  onClick: () => handleOpenInfoFee(FeeType.NETWORK),
                },
                {
                  label: `Matching Fee (${BUYING_MATCHING_FEE}%)`,
                  value: fees.taker,
                  onClick: () => handleOpenInfoFee(FeeType.MATCHING),
                },
                {
                  label: `Alpha Phase Discount (${ALPHA_DISCOUNT}%)`,
                  value: `- ${fees.takerDiscountAlpha}`,
                  onClick: () => handleOpenInfoFee(FeeType.MATCHING),
                },
              ]}
              totalFees={fees.total}
            />
          </motion.div>

          {!canPay && (
            <span className="text-xs text-red-500">Insufficient balance</span>
          )}

          <motion.div
            layout="position"
            transition={{ duration: 0.1 }}
            className="flex flex-col space-y-2"
          >
            <Button
              size="large"
              variant="inverse"
              onClick={handleBuy}
              disabled={loading || !canPay}
            >
              {loading ? <Spinner size={24} /> : "Buy Now"}
            </Button>
            <LegalFooter label="Buy Now" />
          </motion.div>
        </motion.div>
      </div>

      <ModalInfo
        info={info}
        isVisible={infoVisible}
        onClose={handleCloseInfo}
      />
    </AnimatedContent>
  );
};
