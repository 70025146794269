import React, { useMemo } from "react";

import { ListItem, Spinner } from "@/components";
import { useFetchDrc20 } from "@/hooks";
import { shortenAddress } from "@/lib/address";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { WONKY_ORD_URL } from "@/constants";
import { useNavigate } from "react-router-dom";
import { AllDrc20Data } from "@/types";

interface TabTokenDetailsProps {
  tick: string | undefined;
  tokenData: AllDrc20Data | undefined;
}

export const TabTokenDetails: React.FC<TabTokenDetailsProps> = ({
  tick,
  tokenData,
}) => {
  const { loading } = useFetchDrc20();
  const navigate = useNavigate();

  const dataDetail = useMemo(() => {
    if (!tokenData) return [];

    const {
      added,
      limitPerMint,
      minted,
      mintingTransactions,
      deployer,
      deploymentShibescription,
      maxSupply,
      holders,
      rank,
    } = tokenData;

    return [
      {
        label: "Inscription ID",
        value: shortenAddress(deploymentShibescription),
        onClick: () =>
          window.open(
            `${WONKY_ORD_URL}shibescription/${deploymentShibescription}`,
            "_blank",
          ),
      },
      {
        label: "Deployed By",
        value: shortenAddress(deployer),
        onClick: () => navigate(`/wallet/${deployer}`),
      },
      { label: "Deployed Time", value: added },
      {
        label: "Supply",
        value: maxSupply
          ? formatNumber({
              value: maxSupply,
              type: NumberFormatType.Large_Number,
            })
          : 0,
      },
      { label: "Minted", value: minted },
      { label: "Limit Per Mint", value: limitPerMint },
      {
        label: "Owners",
        value: holders
          ? formatNumber({
              value: holders,
              type: NumberFormatType.Large_Number,
            })
          : 0,
      },
      { label: "Deploy Number", value: rank ?? "N/A" },
      {
        label: "Mint Transactions",
        value: mintingTransactions
          ? formatNumber({
              value: mintingTransactions,
              type: NumberFormatType.Large_Number,
            })
          : 0,
      },
    ];
  }, [tokenData, navigate]);

  if (loading) {
    return (
      <div className="flex h-96 flex-1 flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mx-auto flex max-w-2xl flex-1 flex-col p-4">
      <div className="flex flex-1 flex-col space-y-4">
        <div className="text-center text-md font-bold text-text-primary">{`${tick?.toUpperCase()} Details`}</div>
        <div className="flex flex-col rounded-lg bg-background-primary/50 p-2 2xl:bg-background-secondary">
          {dataDetail.map(({ label, value, onClick }) => (
            <ListItem
              key={label}
              label={label}
              detail={value}
              onClick={onClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
