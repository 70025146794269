import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { Button, ImageWithFallback } from "@/components";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { CollectionData, Currency } from "@/types";
import { getValueForCurrency } from "@/utility";
import { ColumnDef } from "@tanstack/react-table";

import { ChangeCell, HeaderCell, NumberCell, TextCell } from "../components";

export const TableContentCollection: ColumnDef<CollectionData>[] = [
  // number column temporary disabled
  // {
  //   accessorKey: "rank",
  //   header: () => {
  //     return <HeaderCell title="#" aligned="left" className="w-10 pl-4" />;
  //   },
  //   cell: ({ row }) => {
  //     const rank = row.getValue("rank")?.toString() || "";
  //     return <TextCell value={rank} className="w-10 pl-4 text-left" />;
  //   },
  // },
  {
    accessorKey: "fdv",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Market Cap"
          aligned="left"
          className="w-40 pl-4 lg:w-52"
          column={column}
          withSorting
        />
      );
    },
    cell: ({ row }) => {
      const {
        name,
        fdv,
        currentDogePrice,
        currency,
        imageURI,
        collectionSymbol,
      } = row.original;

      const currencyToUse =
        currency === Currency.USD ? Currency.USD : Currency.DOGE;

      const valueForCurrency = getValueForCurrency(
        fdv || 0,
        currencyToUse,
        currentDogePrice || 0,
      );

      const value = formatNumber({
        value: valueForCurrency,
        type: NumberFormatType.Large_Number,
      });

      return (
        <Link to={`/collectible/${collectionSymbol.toLowerCase()}`}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.1 }}
          >
            <div className="flex w-32 flex-row items-center space-x-2 lg:w-52 lg:space-x-4">
              <ImageWithFallback
                image={imageURI}
                className="h-7 w-7 rounded-md lg:h-10 lg:w-10"
              />

              <div className="flex flex-col lg:space-y-1">
                <span className="w-24 truncate text-sm font-medium lg:w-36 lg:text-md">
                  {name}
                </span>
                <NumberCell
                  value={value}
                  currency={currencyToUse}
                  className="w-fit text-xs text-text-tertiary lg:text-sm"
                />
              </div>
            </div>
          </motion.div>
        </Link>
      );
    },
  },
  {
    accessorKey: "price",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Floor"
          aligned="right"
          column={column}
          withSorting
          className="w-20"
        />
      );
    },
    cell: ({ row }) => {
      const price = row.getValue("price") as number;
      const { currentDogePrice, currency } = row.original;

      const currencyToUse =
        currency === Currency.USD ? Currency.USD : Currency.DOGE;

      const valueForCurrency = getValueForCurrency(
        price,
        currencyToUse,
        currentDogePrice || 0,
      );

      const value = formatNumber({
        value: valueForCurrency,
        type: NumberFormatType.Price,
      });

      return (
        <NumberCell value={value} currency={currencyToUse} className="w-20" />
      );
    },
  },
  {
    accessorKey: "change24h",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="24h"
          aligned="right"
          column={column}
          withSorting
          className="w-18 pl-6 lg:w-24 lg:pl-0"
        />
      );
    },
    cell: ({ row }) => {
      const { change24h } = row.original;
      return <ChangeCell change={change24h || 0} className="lg:w-24" />;
    },
  },
  {
    accessorKey: "change7d",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="7d"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { change7d } = row.original;
      return <ChangeCell change={change7d || 0} className="w-24" />;
    },
  },
  {
    accessorKey: "change30d",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="30d"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { change30d } = row.original;
      return <ChangeCell change={change30d || 0} className="w-24" />;
    },
  },
  // {
  //   accessorKey: "volume7d",
  //   header: ({ column }) => {
  //     return (
  //       <HeaderCell
  //         title="Vol (7d)"
  //         aligned="right"
  //         column={column}
  //         withSorting
  //         className="w-24"
  //       />
  //     );
  //   },
  //   cell: ({ row }) => {
  //     const { currentDogePrice, currency, volume7d } = row.original;
  //
  //     const currencyToUse =
  //       currency === Currency.USD ? Currency.USD : Currency.DOGE;
  //
  //     const valueForCurrency = getValueForCurrency(
  //       volume7d || 0,
  //       currencyToUse,
  //       currentDogePrice || 0,
  //     );
  //
  //     const value = formatNumber({
  //       value: valueForCurrency,
  //       type: NumberFormatType.Large_Number,
  //     });
  //
  //     return (
  //       <NumberCell
  //         value={value}
  //         currency={currencyToUse}
  //         hideZero
  //         className="w-24"
  //       />
  //     );
  //   },
  // },
  {
    id: "volumeThirtyDays",
    accessorKey: "volume30d",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Vol (30d)"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { currentDogePrice, currency, volume30d } = row.original;

      const currencyToUse =
        currency === Currency.USD ? Currency.USD : Currency.DOGE;

      const valueForCurrency = getValueForCurrency(
        volume30d || 0,
        currencyToUse,
        currentDogePrice || 0,
      );

      const value = formatNumber({
        value: valueForCurrency,
        type: NumberFormatType.Large_Number,
      });

      return (
        <NumberCell
          value={value}
          currency={currencyToUse}
          hideZero
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "volume",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Vol (All)"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const volume = row.getValue("volume") as number;
      const { currentDogePrice, currency } = row.original;
      const currencyToUse =
        currency === Currency.USD ? Currency.USD : Currency.DOGE;

      const valueForCurrency = getValueForCurrency(
        volume,
        currencyToUse,
        currentDogePrice || 0,
      );

      const value = formatNumber({
        value: valueForCurrency,
        type: NumberFormatType.Large_Number,
      });

      return (
        <NumberCell
          value={value}
          currency={currencyToUse}
          hideZero
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "sales7d",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Sales (7d)"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { sales7d } = row.original;

      const value =
        sales7d === undefined
          ? "0"
          : formatNumber({
              value: sales7d,
              type: NumberFormatType.Large_Number,
            });
      return <NumberCell value={value} hideZero className="w-24" />;
    },
  },
  // {
  //   accessorKey: "sales",
  //   header: ({ column }) => {
  //     return (
  //       <HeaderCell
  //         title="Sales (All)"
  //         aligned="right"
  //         column={column}
  //         withSorting
  //         className="w-24"
  //       />
  //     );
  //   },
  //   cell: ({ row }) => {
  //     const sales = row.getValue("sales") as number;
  //     const value =
  //       sales === undefined
  //         ? "0"
  //         : formatNumber({ value: sales, type: NumberFormatType.Large_Number });
  //     return <NumberCell value={value} hideZero className="w-24" />;
  //   },
  // },
  {
    accessorKey: "holders",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Owners"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const holders = row.getValue("holders") as number;
      const value = formatNumber({
        value: holders,
        type: NumberFormatType.Large_Number,
      });
      return <NumberCell value={value} hideZero className="w-24" />;
    },
  },
  {
    accessorKey: "listed",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Listed"
          aligned="right"
          column={column}
          withSorting
          className="mr-4 w-24 lg:w-28"
        />
      );
    },
    cell: ({ row }) => {
      const holders = row.getValue("listed") as string;
      return <TextCell value={holders} className="mr-4 w-24 lg:w-28" />;
    },
  },
  {
    accessorKey: "watchlist",
    header: () => {
      return <HeaderCell title="" aligned="left" className="w-8 pr-4" />;
    },
    cell: ({ row }) => {
      const {
        watchlist,
        collectionSymbol,
        onRemoveFromWatchlist,
        onAddToWatchlist,
      } = row.original;

      const onToggleWatchlist = async () => {
        if (watchlist) {
          onRemoveFromWatchlist?.(collectionSymbol);
        } else {
          await onAddToWatchlist?.(collectionSymbol);
        }
      };

      return (
        <div className="flex w-8 flex-col items-center justify-center pr-4">
          <Button
            variant="ghost"
            size="icon"
            shape="circle"
            className={cn(
              "h-5 w-5 hover:text-text-primary",
              watchlist ? "text-text-highlight" : "text-text-tertiary",
            )}
            onClick={onToggleWatchlist}
          >
            <span className="material-symbols-rounded text-2xl">star</span>
          </Button>
        </div>
      );
    },
  },
];
