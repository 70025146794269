import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCopyToClipboard, useToggle } from "usehooks-ts";

import { Button, useToast } from "@/components";
import { useWalletManagement } from "@/contextHooks";
import { useSecurityPreference } from "@/contextHooks";
import {
  useLocalStorage,
  useOperatingSystem,
  usePasswords,
  usePins,
} from "@/hooks";
import { handleError } from "@/utility";

import { Modal, ModalProps, cardTransition, cardVariants } from "../base";
import { OnboardingVariant } from "./constants";
import {
  CompletionView,
  PasswordCreationView,
  RecoveryPhraseInputView,
  RecoveryPhraseView,
  StartingView,
} from "./views";
import { HAS_COMPLETED_WALLET_SETUP_KEY } from "@/constants.ts";

interface OnboardingState {
  variant: OnboardingVariant | undefined;
  step: number;
}

export const ModalMissingWallet: React.FC<ModalProps> = ({
  onClose,
  onCancel,
  ...props
}) => {
  const [onboarding, setOnboarding] = useState<OnboardingState>({
    variant: undefined,
    step: 0,
  });

  const walletManagementContext = useWalletManagement();
  const { createWallet, updateWallet, importWallet } =
    walletManagementContext || {};

  const { securityPreference, setSecurityPreference } = useSecurityPreference();
  const [, copy] = useCopyToClipboard();
  const { toast } = useToast();
  const { isIOS } = useOperatingSystem();
  // the third parameter (false) needs to be set to the same value as the value for USER_SECURITY_PREFERENCE_KEY and USER_WALLET_KEY
  const { updateValue: updateHasCompletedWalletSetup } = useLocalStorage<
    string | null
  >(HAS_COMPLETED_WALLET_SETUP_KEY, null, false);

  const [mnemonic, setMnemonic] = useState<string | undefined>(undefined);
  const [words, setWords] = useState(Array(12).fill(""));

  const [acknowledgeWarning, toggleAcknowledgeWarning] = useToggle(false);
  const [savedRecoveryPhrase, toggleSavedRecoveryPhrase] = useToggle(false);

  // Functions
  const handleBack = useCallback(() => {
    const step = onboarding.step - 1;
    if (step === 0) {
      setOnboarding({ variant: undefined, step: 0 });
      return;
    }

    setOnboarding({ ...onboarding, step: onboarding.step - 1 });
  }, [onboarding]);

  // Functions: Step 0
  const handleStartCreate = useCallback(() => {
    setOnboarding({ variant: OnboardingVariant.Create, step: 1 });
  }, []);

  const handleStartImport = useCallback(() => {
    setOnboarding({ variant: OnboardingVariant.Import, step: 1 });
  }, []);

  const handleCancel = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  // Functions: Create – Step 1
  const handleSetSecurityPreference = useCallback(
    (preference: "pin" | "password") => {
      setSecurityPreference(preference);
    },
    [setSecurityPreference],
  );

  // Set default security preference
  useEffect(() => {
    if (securityPreference === undefined) {
      setSecurityPreference("pin");
    }
  }, [securityPreference, setSecurityPreference]);

  // PIN
  const { pins, handleSetPin, handleClearPin, isPinSet, pinsMatch } = usePins({
    pinNames: ["pin", "repeatPin"],
  });

  const pinsSet = useMemo(
    () => isPinSet("pin") && isPinSet("repeatPin"),
    [isPinSet],
  );

  const isPinMatching = useMemo(
    () => pinsMatch("pin", "repeatPin"),
    [pinsMatch],
  );

  const handleChangePins = useCallback(
    (type: "pin" | "repeatPin", index: number, value: string) => {
      handleSetPin(type, index, value);
    },
    [handleSetPin],
  );

  // Password

  const {
    passwords,
    handleSetPassword,
    handleClearPassword,
    passwordSet,
    passwordRulesMet,
    passwordsMatch,
  } = usePasswords({
    passwordNames: ["password", "repeatPassword"],
  });

  const handleChangePasswords = useCallback(
    (passwordName: "password" | "repeatPassword", value: string) => {
      handleSetPassword(passwordName, value);
    },
    [handleSetPassword],
  );

  const passwordsSet = useMemo(
    () => passwordSet("password") && passwordSet("repeatPassword"),
    [passwordSet],
  );

  const isPasswordMatching = useMemo(() => {
    return passwordsMatch("password", "repeatPassword");
  }, [passwordsMatch]);

  const allRulesMet = useMemo(() => {
    return passwordRulesMet("password");
  }, [passwordRulesMet]);

  const handleCreateWalletWithPassword = useCallback(async () => {
    try {
      const mnemonic = await createWallet?.(passwords.password);
      setMnemonic(mnemonic);
      setOnboarding({ variant: OnboardingVariant.Create, step: 2 });
    } catch (e: Error | unknown) {
      const message = handleError(e);
      toast({
        title: "Error Creating Wallet",
        variant: "error",
        description: message,
      });
      handleClearPassword("password");
      handleClearPassword("repeatPassword");
    }
  }, [createWallet, passwords, handleClearPassword, toast]);

  const handleCreateWalletWithPin = useCallback(async () => {
    try {
      const pin = pins.pin.join("");
      const mnemonic = await createWallet?.(pin);
      setMnemonic(mnemonic);
      setOnboarding({ variant: OnboardingVariant.Create, step: 2 });
    } catch (e: Error | unknown) {
      const message = handleError(e);
      toast({
        title: "Error Creating Wallet",
        variant: "error",
        description: message,
      });
      handleClearPin("pin");
      handleClearPin("repeatPin");
    }
  }, [createWallet, handleClearPin, pins.pin, toast]);

  // Functions: Create – Step 2
  const handleResumeWalletCreation = useCallback(() => {
    if (!updateWallet || !walletManagementContext?.wallet) {
      toast({
        title: "Wallet could not be saved.",
        variant: "error",
        description: "Something went wrong. Please try again.",
      });
    }
    updateWallet?.({
      ...walletManagementContext?.wallet?.walletData,
      hasBackedUpSeed: true,
    });
    setOnboarding({ variant: OnboardingVariant.Create, step: 3 });
  }, [toast, updateWallet, walletManagementContext?.wallet]);

  const handleCopyRecoveryPhrase = useCallback(() => {
    copy(mnemonic!);
  }, [copy, mnemonic]);

  // Functions: Import – Step 1
  const handleImportContinue = useCallback(() => {
    setOnboarding({ variant: OnboardingVariant.Import, step: 2 });
  }, []);

  // Functions: Import – Step 2
  const handleChangeWords = useCallback(
    (index: number, value: string) => {
      const newWords = [...words];
      newWords[index] = value;
      setWords(newWords);
    },
    [words],
  );

  const allWordsEntered = useMemo(
    () => words.every((word) => word.length > 0),
    [words],
  );

  const handlePasteRecoveryPhrase = async () => {
    if (!isIOS) {
      try {
        const text = await navigator.clipboard.readText();
        const wordsFromClipboard = text
          .split(/\s+/)
          .filter((word) => word !== "");
        if (wordsFromClipboard.length === 12) {
          setWords(wordsFromClipboard);
        } else {
          toast({
            title: "Invalid Recovery Phrase",
            variant: "error",
            description: "The clipboard does not contain exactly 12 words.",
          });
        }
      } catch (e: Error | unknown) {
        const message = handleError(e);
        toast({
          title: "Failed to Paste Recovery Phrase",
          variant: "error",
          description: message,
        });
      }
    }
  };

  const handleImportWallet = useCallback(async () => {
    try {
      const phrase = words.map((word) => word.trim()).join(" ");
      // Use password if security preference is password, otherwise use pin
      const pw =
        securityPreference === "password"
          ? passwords.password
          : pins.pin.join("");
      await importWallet?.(pw, phrase);
      setOnboarding({ variant: OnboardingVariant.Import, step: 3 });
    } catch (e: Error | unknown) {
      const message = handleError(e);

      toast({
        title: "Error Importing Wallet",
        variant: "error",
        description: message,
      });
    }
  }, [importWallet, passwords, pins, securityPreference, words, toast]);

  // Functions: Step 3
  const handleFinish = useCallback(() => {
    updateHasCompletedWalletSetup("true");
    onClose?.();
  }, [onClose, updateHasCompletedWalletSetup]);

  return (
    <Modal classNameContent="justify-center p-4" {...props}>
      <motion.div
        key="modal-card"
        initial="closed"
        animate="open"
        exit="closed"
        variants={cardVariants}
        transition={cardTransition}
        className="lg:max-2xl: relative mx-auto flex aspect-3/4 w-full max-w-xl flex-col overflow-clip rounded-xl bg-background-secondary text-text-primary lg:rounded-2xl"
      >
        {onboarding.variant && onboarding.step === 1 && (
          <Button
            size="icon"
            shape="circle"
            className="absolute left-2 top-2 z-10 border-none bg-background-secondary"
            onClick={handleBack}
          >
            <span className="material-symbols-rounded text-xs">
              arrow_back_ios_new
            </span>
          </Button>
        )}

        <AnimatePresence>
          {onboarding.variant === undefined && (
            <div className="relative flex flex-1 flex-col justify-end">
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                onClick={handleCancel}
                className="absolute right-0 top-0 z-10 m-4"
              >
                <span className="material-symbols-rounded text-lg">close</span>
              </Button>

              <div className="flex flex-1 items-end justify-end">
                <StartingView
                  onStartImport={handleStartImport}
                  onStartCreate={handleStartCreate}
                />
              </div>
            </div>
          )}

          {onboarding.step === 1 && (
            <PasswordCreationView
              passwords={{
                password: passwords.password,
                repeatPassword: passwords.repeatPassword,
              }}
              allRulesMet={allRulesMet}
              passwordsMatch={isPasswordMatching}
              passwordsSet={passwordsSet}
              onPasswordsChange={handleChangePasswords}
              onContinueWithPassword={
                onboarding.variant === OnboardingVariant.Create
                  ? handleCreateWalletWithPassword
                  : handleImportContinue
              }
              onContinueWithPin={
                onboarding.variant === OnboardingVariant.Create
                  ? handleCreateWalletWithPin
                  : handleImportContinue
              }
              onSwitchSecurityPreference={handleSetSecurityPreference}
              pins={{
                pin: pins.pin,
                repeatPin: pins.repeatPin,
              }}
              onPinsChange={handleChangePins}
              pinsMatch={isPinMatching}
              pinsSet={pinsSet}
              initialSecurityPreference={securityPreference}
            />
          )}

          {onboarding.variant === OnboardingVariant.Create &&
            onboarding.step === 2 && (
              <RecoveryPhraseView
                mnemonic={mnemonic}
                acknowledgeWarning={acknowledgeWarning}
                savedRecoveryPhrase={savedRecoveryPhrase}
                onContinue={handleResumeWalletCreation}
                onCopyRecoveryPhrase={handleCopyRecoveryPhrase}
                toggleAcknowledgeWarning={toggleAcknowledgeWarning}
                toggleSavedRecoveryPhrase={toggleSavedRecoveryPhrase}
              />
            )}

          {onboarding.variant === OnboardingVariant.Import &&
            onboarding.step === 2 && (
              <RecoveryPhraseInputView
                onContinue={handleImportWallet}
                hidePasteRecoveryPhrase={isIOS}
                onPasteRecoveryPhrase={handlePasteRecoveryPhrase}
                onChangeWords={handleChangeWords}
                words={words}
                allWordsEntered={allWordsEntered}
              />
            )}

          {onboarding.step === 3 && (
            <CompletionView
              variant={onboarding.variant}
              onClose={handleFinish}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </Modal>
  );
};
