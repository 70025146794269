import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { LOW_NETWORK_FEE_RATE } from "@/constants";
import { FeeType } from "@/context/wallet/calculateNetworkFees.ts";
import { useTxWallet } from "@/contextHooks";
import { useGetMaxSendableDune } from "@/hooks";
import { shortenAddress } from "@/lib/address";
import { Currency, DuneListing } from "@/types";
import {
  calculateTokenAmountFromUSD,
  calculateTokenValueInUSD,
  filterInvalidCharacters,
  isValidDogecoinAddress,
} from "@/utility";

import {
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { SendTransactionStep } from "./constants";
import { AmountView, ConfirmView, RecipientView } from "./views";
import { getFilteredDuneUtxos } from "@/hooks/datafetching/useGetDuneUtxosWithoutListings";

interface ModalSendDuneProps extends SheetProps {
  token: string;
  listings?: DuneListing[];
}

enum IconBack {
  BACK = "arrow_back_ios",
  CLOSE = "close",
}

interface StepDetails {
  flowIndex: number;
  title: string;
  iconBack?: IconBack;
}

const FLOW = [
  SendTransactionStep.RECIPIENT,
  SendTransactionStep.AMOUNT,
  SendTransactionStep.CONFIRM,
];

export const ModalSendDune: React.FC<ModalSendDuneProps> = ({
  token,
  onClose,
  listings,
  ...props
}) => {
  const {
    sendDune,
    calculateNetworkFees,
    accountDuneData,
    dogePrice,
    txWallet,
  } = useTxWallet();

  const { floorPrice } = accountDuneData.tokenData || {};
  const { utxos, availableBalance } = accountDuneData.accountDunesData || {};

  const [amount, setAmount] = useState<string>("");
  const [recipient, setRecipient] = useState<string>("");
  const [transactionStep, setTransactionStep] = useState(FLOW[0]);
  const [currency, setCurrency] = useState<Currency.USD | string>(token);
  const [amountError, setAmountError] = useState<string | undefined>();
  const [transactionFee, setTransactionFee] = useState<string>(
    `${Currency.DOGE} N/A`,
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);

  const [error, setError] = useState({
    title: "Error",
    description: "An error occurred while processing your request.",
  });

  const { maxListable } = useGetMaxSendableDune({
    accountData: accountDuneData.accountDunesData,
    amount,
    token,
    listings,
    isForSending: true,
  });

  // Get the current step details
  const { flowIndex, title, iconBack } = useMemo<StepDetails>(() => {
    const flowIndex = FLOW.indexOf(transactionStep);

    let title = "";
    let iconBack = IconBack.BACK;

    switch (transactionStep) {
      case SendTransactionStep.RECIPIENT:
        title = "Recipient";
        iconBack = IconBack.CLOSE;
        break;
      case SendTransactionStep.AMOUNT:
        title = "Amount";
        break;
      case SendTransactionStep.CONFIRM:
        title = "Confirm";
        break;
      default:
        title = "";
    }

    return {
      flowIndex,
      title,
      iconBack,
    };
  }, [transactionStep]);

  // Dont add accountDuneData to the dependencies. It will cause an infinite loop
  useEffect(() => {
    if (
      token &&
      accountDuneData.getDuneAccountData &&
      !accountDuneData.loading
    ) {
      accountDuneData.getDuneAccountData(token, true).then();
    }
  }, [token, accountDuneData.getDuneAccountData]);

  // Shorten the recipient address
  const recipientShort = useMemo(() => {
    const shortenedAddress = shortenAddress(recipient);
    if (!shortenedAddress) return;
    return shortenedAddress;
  }, [recipient]);

  const amountInToken = useMemo<number>(() => {
    if (currency === token) {
      return parseFloat(amount);
    }

    return parseFloat(
      calculateTokenAmountFromUSD(
        parseFloat(amount),
        floorPrice || 0,
        dogePrice || 0,
      ),
    );
  }, [amount, currency, token, floorPrice, dogePrice]);

  const maxAmountInUSD = useMemo(() => {
    return parseFloat(
      calculateTokenValueInUSD(maxListable, floorPrice || 0, dogePrice || 0),
    );
  }, [maxListable, floorPrice, dogePrice]);

  const amountDetail = useMemo(() => {
    const tokenCurrency = token.toUpperCase();

    if (currency === Currency.USD) {
      // If the user sets the currency to $, show the amount in token value; but never show more than the available balance
      const calculatedAmount = calculateTokenAmountFromUSD(
        parseFloat(amount),
        floorPrice || 0,
        dogePrice || 0,
      );

      const maximumAmount = Math.min(maxListable, parseFloat(calculatedAmount));

      return `${maximumAmount || 0}${tokenCurrency}`;
    }

    // If the user sets the currency to token, show the amount in USD value; but never show more than the available balance
    const calculatedAmount = calculateTokenValueInUSD(
      parseFloat(amount),
      floorPrice || 0,
      dogePrice || 0,
    );

    const maximumAmount = Math.min(
      maxAmountInUSD,
      parseFloat(calculatedAmount),
    );

    return `${maximumAmount || 0}${Currency.USD}`;
  }, [
    token,
    amount,
    floorPrice,
    dogePrice,
    maxListable,
    maxAmountInUSD,
    currency,
  ]);

  /**
   * Functions
   */

  const handleNext = useCallback(async () => {
    if (flowIndex < FLOW.length - 1) {
      setTransactionStep(FLOW[flowIndex + 1]);
    }
  }, [flowIndex]);

  const handleBack = useCallback(() => {
    if (flowIndex > 0) {
      setTransactionStep(FLOW[flowIndex - 1]);
    } else {
      setRecipient("");
      setAmount("0");
      onClose?.();
    }
  }, [flowIndex, onClose]);

  const handleSetError = useCallback(
    (title: string, description: string) => {
      setError({ title, description });
      setTransactionStep(SendTransactionStep.ERROR);
    },
    [setError],
  );

  /**
   * Step 1: Recipient
   */

  const recipientError = useMemo(() => {
    // Return undefined immediately if recipient is an empty string
    if (recipient === "") {
      return undefined;
    }
    // Check if the recipient's address is valid, return undefined if valid, otherwise return error message
    return isValidDogecoinAddress(recipient) ? undefined : "Invalid address";
  }, [recipient]);

  const handleRecipientChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRecipient(filterInvalidCharacters(e.target.value));
    },
    [setRecipient],
  );

  const handlePasteAddress = useCallback(() => {
    navigator.clipboard.readText().then((text) => {
      setRecipient(filterInvalidCharacters(text));
    });
  }, [setRecipient]);

  const handleClearAddress = useCallback(() => {
    setRecipient("");
  }, []);

  /**
   * Step 2: Amount
   */
  const handleSetAmount = useCallback(
    (value: string) => {
      // Set the amount; Never set the amount higher than the available balance maxAmountInToken or maxAmountInUSD
      const maxAmount = currency === token ? maxListable : maxAmountInUSD;
      if (parseFloat(value) > maxAmount) {
        setAmount(maxListable.toString());
      } else {
        setAmount(value);
      }
    },
    [maxListable, maxAmountInUSD, currency, token],
  );

  // @todo reomove duplicate code - modalListDRC20
  const handleAmountChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const cleanInput = inputValue.replace(/[^0-9.]/g, "");

      // Validate against non-numeric characters but allow empty string and dots for decimal input
      const validInput =
        cleanInput === "" || /^0$|^[1-9]\d*(\.\d*)?$|^0\.\d*$/.test(cleanInput);

      if (validInput) {
        handleSetAmount(cleanInput);
      } else if (!inputValue) {
        handleSetAmount("0");
      }
    },
    [handleSetAmount],
  );

  const handleSwitchCurrency = useCallback(() => {
    handleSetAmount("0");
    setCurrency((prev) => (prev === Currency.USD ? token : Currency.USD));
  }, [handleSetAmount, token]);

  const handleSetMax = useCallback(async () => {
    if (currency === token) {
      handleSetAmount(maxListable.toString());
    } else {
      handleSetAmount(maxAmountInUSD.toString());
    }
  }, [maxListable, maxAmountInUSD, currency, handleSetAmount, token]);

  /**
   * Step 3: Confirm
   */
  // Calculate the network fees & set the transactions
  useEffect(() => {
    if (transactionStep === SendTransactionStep.CONFIRM && txWallet && utxos) {
      calculateNetworkFees.execute(FeeType.DUNE, {
        tick: token,
        feePerVByte: LOW_NETWORK_FEE_RATE,
        receiverAddress: recipient,
        amt: amountInToken,
        duneUtxos: getFilteredDuneUtxos(listings, utxos),
        isClearRequired: false,
      });
    }
  }, [
    transactionStep,
    recipient,
    token,
    amountInToken,
    utxos,
    calculateNetworkFees,
    txWallet,
    listings,
  ]);

  useEffect(() => {
    if (calculateNetworkFees.isError && calculateNetworkFees.error) {
      setTransactionStep(SendTransactionStep.ERROR);
      handleSetError(
        "Error",
        (calculateNetworkFees.error as string) ||
          "An error occurred while calculating your fees.",
      );
    }
  }, [
    calculateNetworkFees.error,
    calculateNetworkFees.isError,
    handleSetError,
  ]);

  useEffect(() => {
    if (calculateNetworkFees.isSuccess) {
      setTransactionFee(
        `${Currency.DOGE}${calculateNetworkFees.totalFeeInDoge}`,
      );
    }
  }, [calculateNetworkFees.isSuccess, calculateNetworkFees.totalFeeInDoge]);

  const handleToggleCheckbox = useCallback(() => {
    setIsCheckboxChecked((prev) => !prev);
  }, []);

  const handleSend = useCallback(async () => {
    if (utxos) {
      await sendDune.execute({
        receiverAddress: recipient,
        tick: token,
        amt: amountInToken,
        duneUtxos: getFilteredDuneUtxos(listings, utxos),
        feePerVByte: LOW_NETWORK_FEE_RATE,
        isClearRequired: false,
      });
    }
  }, [utxos, sendDune, recipient, token, amountInToken, listings]);

  /**
   * Step 4: Success / Error
   */
  useEffect(() => {
    if (sendDune.isError && sendDune.error && !sendDune.isLoading) {
      setTransactionStep(SendTransactionStep.ERROR);
      handleSetError(
        "Error",
        (sendDune.error as string) ||
          "An error occurred while processing your request.",
      );
    }
  }, [handleSetError, sendDune.error, sendDune.isError, sendDune.isLoading]);

  useEffect(() => {
    if (sendDune.isSuccess && sendDune.txHashes && !sendDune.isLoading) {
      setTransactionStep(SendTransactionStep.SUCCESS);
      setTimeout(() => {
        accountDuneData.getDuneData(token).then();
        accountDuneData.getDuneAccountData(token).then();
      }, 1000);
    }
  }, [
    accountDuneData.getDuneData,
    accountDuneData.getDuneAccountData,
    sendDune.isSuccess,
    sendDune.txHashes,
    token,
    sendDune.isLoading,
    accountDuneData,
  ]);

  const reset = useCallback(() => {
    setAmount("");
    setRecipient("");
    setTransactionStep(FLOW[0]);
    setCurrency(token);
    setAmountError(undefined);
    setTransactionFee(`${Currency.DOGE} N/A`);
    setIsCheckboxChecked(false);
    setError({
      title: "Error",
      description: "An error occurred while processing your request.",
    });
  }, [token]);

  const handleClose = useCallback(() => {
    reset();
    onClose?.();
  }, [onClose, reset]);

  const handleOpenTransactions = useCallback(() => {
    if (!sendDune.txHashes) return;
    sendDune.txHashes.forEach((txHash) => {
      window.open(`https://dogechain.info/tx/${txHash}`, "_blank");
    });
  }, [sendDune.txHashes]);

  return (
    <Sheet
      withHeader={false}
      classNameContent="flex flex-col text-text-primary aspect-3/4 max-h-[800px] sm:h-[90vh] sm:aspect-none"
      {...props}
    >
      <ModalNavigation
        title={title}
        subtitle={recipientShort}
        iconBack={iconBack}
        onBack={handleBack}
      />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === SendTransactionStep.RECIPIENT && (
            <RecipientView
              handleValueChange={handleRecipientChange}
              handleContinue={handleNext}
              handlePasteAddress={handlePasteAddress}
              handleClearAddress={handleClearAddress}
              value={recipient}
              errorMessage={recipientError}
            />
          )}

          {transactionStep === SendTransactionStep.AMOUNT && (
            <AmountView
              handleValueChange={handleAmountChange}
              handleSwitchCurrency={handleSwitchCurrency}
              handleSetMax={handleSetMax}
              handleContinue={handleNext}
              value={amount}
              currency={currency}
              detail={amountDetail}
              balance={availableBalance?.toString() || "Loading …"}
              errorMessage={amountError}
              hasBalance={parseFloat(availableBalance?.toString() ?? "0") > 0}
            />
          )}

          {transactionStep === SendTransactionStep.CONFIRM && (
            <ConfirmView
              handleContinue={handleSend}
              isSendLoading={
                sendDune.isLoading || calculateNetworkFees.isLoading
              }
              recipient={recipient}
              amount={amount}
              currency={currency}
              fee={
                calculateNetworkFees.isLoading ? "Loading …" : transactionFee
              }
              serviceFee="100% Discount"
              checkbox={{
                visible: false,
                onClick: () => handleToggleCheckbox(),
                value: isCheckboxChecked,
              }}
            />
          )}

          {transactionStep === SendTransactionStep.SUCCESS && (
            <SuccessView
              handleContinue={handleClose}
              handleOpenTransactions={handleOpenTransactions}
            />
          )}
          {transactionStep === SendTransactionStep.ERROR && (
            <ErrorView
              handleContinue={handleClose}
              title={error.title}
              description={error.description}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
