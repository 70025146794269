import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";

import { FeeType } from "@/context/wallet/calculateNetworkFees";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { CollectionListingProps, Currency, InscriptionType } from "@/types";
import { CalculateFeesReturn, getFormattedValue } from "@/utility";

import {
  DEFAULT_ERROR,
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { BuyTransactionStep } from "./constants";
import { BuyView } from "./views";
import {
  ALPHA_DISCOUNT,
  BUYING_MATCHING_FEE,
  ONE_DOGE_IN_SHIBES,
} from "@/constants.ts";

interface ModalBuyCollectibleProps extends SheetProps {
  offers?: CollectionListingProps[];
  refetchListings: (resetListing?: boolean) => Promise<void>;
  removeFromListings?: (offerId: string) => void;
}

type ProcessOffersReturn = {
  pay: {
    value: string;
    valueInUSD: string;
  };
  receive: {
    amount: number;
    averageItemPrice: string;
  };
  collectionSymbol?: string;
  collectionName?: string;
  totalValuePay?: number;
};

const processOffers = ({
  offers,
  dogePrice,
  currency,
}: {
  offers?: CollectionListingProps[];
  dogePrice: number;
  currency: Currency;
}): ProcessOffersReturn => {
  if (offers === undefined || offers?.length === 0) {
    return {
      pay: { value: "N/A", valueInUSD: "N/A" },
      receive: { amount: 0, averageItemPrice: "N/A" },
      collectionSymbol: undefined,
      collectionName: undefined,
    };
  }

  let totalValuePay = 0;
  let totalAmountReceive = 0;
  const collectionSymbol = offers[0].collectionSymbol;
  const collectionName = offers[0].collectionSymbol;

  // Aggregate total values from the offers
  for (const { price } of offers) {
    totalValuePay += price;
    totalAmountReceive += 1;
  }

  // // Get the formatted total price in both DOGE and USD
  const { valueInDoge, valueInUSD } = getFormattedValue(
    totalValuePay,
    dogePrice,
    currency,
  );

  // // Extract numeric values from formatted strings
  const totalPriceValueForCurrency =
    currency === Currency.DOGE
      ? parseFloat(valueInDoge.split(" ")[1])
      : parseFloat(valueInUSD.split(" ")[1]);

  // // Calculate and format the average unit price
  const averageItemPrice = totalPriceValueForCurrency / totalAmountReceive;
  const formattedAverageItemPrice = formatNumber({
    value: averageItemPrice,
    type: NumberFormatType.Price,
  });

  return {
    pay: {
      value: valueInDoge,
      valueInUSD,
    },
    receive: {
      amount: totalAmountReceive,
      averageItemPrice: `at ${formattedAverageItemPrice} ${currency}/${collectionName}`,
    },
    collectionSymbol,
    collectionName,
    totalValuePay,
  };
};

const calculateFees = ({
  offers,
  txFeeInSats,
}: {
  offers?: CollectionListingProps[];
  txFeeInSats: number;
}): CalculateFeesReturn => {
  if (!offers?.length)
    return {
      network: "N/A",
      taker: "N/A",
      total: "N/A",
      takerDiscountAlpha: "N/A",
    };

  let totalFeeInShibes = 0;

  offers.forEach((offer) => {
    const { price } = offer;
    const feeInShibes = (BUYING_MATCHING_FEE / 100) * price;

    totalFeeInShibes += feeInShibes;
  });

  const takerFeeInDoge = totalFeeInShibes / ONE_DOGE_IN_SHIBES;
  const takerDiscountAlpha = (takerFeeInDoge / 100) * ALPHA_DISCOUNT;
  const networkFeeInDoge = txFeeInSats / ONE_DOGE_IN_SHIBES;

  return {
    network: `${Currency.DOGE} ${formatNumber({ value: networkFeeInDoge, type: NumberFormatType.Price })}`,
    taker: `${Currency.DOGE} ${formatNumber({ value: takerFeeInDoge, type: NumberFormatType.Price })}`,
    total: `${Currency.DOGE} ${formatNumber({ value: takerFeeInDoge + networkFeeInDoge - takerDiscountAlpha, type: NumberFormatType.Price })}`,
    takerDiscountAlpha: `${Currency.DOGE} ${formatNumber({ value: takerDiscountAlpha, type: NumberFormatType.Price })}`,
  };
};

export const ModalBuyCollectible: React.FC<ModalBuyCollectibleProps> = ({
  onClose,
  offers,
  isVisible,
  removeFromListings,
  refetchListings,
  ...props
}) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { buyItem, calculateNetworkFees, dogeBalance } = useTxWallet();

  // Local State
  const [localIsVisible, setLocalIsVisible] = useState<boolean>(isVisible);
  const [txFeeInSats, setTxFeeInSats] = useState(0);
  const [error, setError] = useState(DEFAULT_ERROR);
  const [transactionStep, setTransactionStep] = useState(
    BuyTransactionStep.BUY,
  );

  useEffect(() => {
    setLocalIsVisible(isVisible);
  }, [isVisible]);

  // Handle buy error
  const handleBuyError = useCallback((title: string, description: string) => {
    setError({ title, description });
    setTransactionStep(BuyTransactionStep.ERROR);
    setLocalIsVisible(true);
  }, []);

  // Build the transaction and set the buyerTx and sellerPsdtHex
  useEffect(() => {
    if (
      !offers?.length ||
      calculateNetworkFees.isLoading ||
      calculateNetworkFees.isError ||
      calculateNetworkFees.isSuccess
    )
      return;

    const offerIds = offers.map((offer) => offer.offerId);
    calculateNetworkFees.execute(FeeType.BUYCOLLECTIBLES, {
      offerIds: offerIds,
    });
  }, [offers]);

  // Close the modal
  const handleClose = useCallback(() => {
    if (buyItem.isLoading) return;

    // reset values
    setError(DEFAULT_ERROR);
    setTransactionStep(BuyTransactionStep.BUY);
    buyItem.reset();
    setLocalIsVisible(false);
    onClose?.();
  }, [buyItem, onClose]);

  useEffect(() => {
    if (calculateNetworkFees.isError && calculateNetworkFees.error) {
      // @todo: this needs to go into a custom hook for error handling calculateNetworkFees

      // ensure the selected listings are updated after an error
      if (calculateNetworkFees.error instanceof Map && removeFromListings) {
        for (const offerId of calculateNetworkFees.error.keys()) {
          removeFromListings(offerId);
        }
      }

      handleBuyError(
        "Error",
        calculateNetworkFees.error instanceof Map
          ? Array.from(calculateNetworkFees.error.entries())
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ")
          : (calculateNetworkFees.error as string) ||
              "An error occurred while calculating your fees.",
      );
    }
  }, [
    calculateNetworkFees.error,
    calculateNetworkFees.isError,
    handleBuyError,
    removeFromListings,
  ]);

  useEffect(() => {
    if (calculateNetworkFees.isSuccess && calculateNetworkFees.totalFeeInSats) {
      setTxFeeInSats(calculateNetworkFees.totalFeeInSats);
    }
  }, [calculateNetworkFees.isSuccess, calculateNetworkFees.totalFeeInSats]);

  // Handle buy success
  const handleBuySuccess = useCallback(() => {
    setTransactionStep(BuyTransactionStep.SUCCESS);
    // Ensure the list is updated after a successful buy
    setTimeout(() => refetchListings(true), 3000);
  }, [refetchListings]);

  // Handle buy transaction
  const handleBuy = useCallback(async () => {
    if (offers?.length) {
      const offerIds = offers.map((offer) => offer.offerId);
      await buyItem.execute(offerIds, InscriptionType.DOGINALS);
    }
  }, [offers, buyItem]);

  // On success, show the success view
  useEffect(() => {
    if (buyItem.isSuccess) {
      handleBuySuccess();
    }
  }, [handleBuySuccess, buyItem.isSuccess]);

  // On error, show the error view
  useEffect(() => {
    if ((buyItem.isError && buyItem.error) || buyItem.errors.length > 0) {
      let message = "An error occurred while processing your request.";
      if (buyItem.errors.length > 0) {
        message = `${message}, ${buyItem.errors.join(",")}`;
      }

      if (buyItem.error) {
        message = `${message}, ${buyItem.error as string}`;
      }

      handleBuyError("Error", message);
    }
  }, [handleBuyError, buyItem.isError, buyItem.error, buyItem.errors]);

  // Displayed fees
  const fees = useMemo(() => {
    return calculateFees({ offers, txFeeInSats });
  }, [offers, txFeeInSats]);

  // Displayed values
  const { pay, canPay } = useMemo(() => {
    const { pay, collectionName, totalValuePay } = processOffers({
      offers,
      dogePrice,
      currency,
    });

    const canPay = dogeBalance.total_shibes >= (totalValuePay ?? 0);

    console.log(dogeBalance, totalValuePay, canPay);

    return {
      pay: { value: pay.value, valueInUSD: pay.valueInUSD },
      collectionName,
      canPay,
    };
  }, [offers, dogePrice, currency, dogeBalance]);

  return (
    <Sheet
      withHeader={false}
      isVisible={localIsVisible}
      classNameContent="flex flex-col text-text-primary h-full rounded-none pt-safe-offset-4 pb-safe-offset-4"
      onClose={handleClose}
      {...props}
    >
      <ModalNavigation
        title="Review Order"
        iconBack="close"
        onBack={handleClose}
      />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === BuyTransactionStep.BUY &&
            fees.network &&
            fees.taker && (
              <BuyView
                canPay={canPay}
                pay={pay}
                handleBuy={handleBuy}
                onRemoveItem={removeFromListings}
                loading={
                  (buyItem.isLoading as boolean) ||
                  (calculateNetworkFees.isLoading as boolean)
                }
                offers={offers}
                fees={{
                  network: fees.network,
                  taker: fees.taker,
                  total: fees.total || "N/A",
                  takerDiscountAlpha: fees.takerDiscountAlpha,
                }}
              />
            )}
          {transactionStep === BuyTransactionStep.SUCCESS && (
            <SuccessView handleContinue={handleClose} />
          )}
          {transactionStep === BuyTransactionStep.ERROR && (
            <ErrorView
              handleContinue={handleClose}
              title={error.title}
              description={error.description}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
