import * as bitcoin from "bitcoinjs-lib";
import * as dogecore from "bitcore-lib-doge";
import * as ecc from "tiny-secp256k1";

import {
  createDuneTxs,
  getTotalTransactionFeeInSats,
} from "@/context/wallet/lib/transaction.ts";

bitcoin.initEccLib(ecc);

import { TxWallet, TxWithFeeEstimation } from "@/context/TxWalletProvider";
import { DunesUtxo } from "@/types";

type BuildSendDuneTxsParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
  receiverAddress: string;
  tick: string;
  amt: number;
  duneUtxos: DunesUtxo[];
  isClearRequired: boolean;
  feePerVByte: number;
  estimateFeesOnly: boolean;
};

const buildSendDuneTxs = async (
  buildSendDuneTxsParams: BuildSendDuneTxsParams,
): Promise<TxWithFeeEstimation[] | undefined> => {
  const {
    txWallet,
    address,
    receiverAddress,
    amt,
    duneUtxos,
    feePerVByte,
    estimateFeesOnly,
    tick,
  } = buildSendDuneTxsParams;

  if (!txWallet) {
    console.warn("buildSendDunes - no wallet found");
    throw new Error("No wallet found");
  }

  if (!address) {
    console.warn("buildSendDunes - no address given");
    throw new Error("No no address given");
  }

  const filteredDuneUtxos = duneUtxos.filter((utxo) => utxo.dunes.dune == tick);

  if (!filteredDuneUtxos) {
    throw new Error("No dunes for transaction found")
  }

  const { id } = filteredDuneUtxos[0]?.dunes || null;

  if (!id) throw "Couldn't get id of dune";

  // array to store all txs that we will broadcast at the end
  let txs: any = [];

  const duneTxs = await createDuneTxs(
    txWallet,
    feePerVByte,
    // check if true or false???
    false,
    filteredDuneUtxos,
    estimateFeesOnly,
    amt,
    id,
    receiverAddress,
  );

  txs = txs.concat(duneTxs).map((tx: any) => ({
    tx,
    txFeeInSats: getTotalTransactionFeeInSats(tx, feePerVByte),
  }));

  return txs;
};

export const parseDuneId = (id: any, claim = false) => {
  // Check if Dune ID is in the expected format
  const regex = /^\d+:\d+$/;
  if (!regex.test(id))
    console.log(`Dune ID ${id} is not in the expected format e.g. 1234/1`);

  // Parse the id string to get height and index
  const [heightStr, indexStr] = id.split(":");
  const height = parseInt(heightStr, 10);
  const index = parseInt(indexStr, 10);

  // Set the bits in the id using bitwise OR
  let duneId = (BigInt(height) << BigInt(16)) | BigInt(index);

  // For minting set CLAIM_BIT
  if (claim) {
    const CLAIM_BIT = BigInt(1) << BigInt(48);
    duneId |= CLAIM_BIT;
  }

  return duneId;
};

export class Edict {
  id: any;
  amount: any;
  output: any;
  // Constructor for Edict
  constructor(id: any, amount: any, output: any) {
    this.id = id;
    this.amount = amount;
    this.output = output;
  }
}

function stringToCharCodes(inputString: string) {
  const charCodes = [];
  for (let i = 0; i < inputString.length; i++) {
    charCodes.push(inputString.charCodeAt(i));
  }
  return charCodes;
}

const IDENTIFIER = stringToCharCodes("D");

const MAX_SCRIPT_ELEMENT_SIZE = 520;

// Encode a u128 value to a byte array
function varIntEncode(n: any) {
  const out = new Array(19).fill(0);
  let i = 18;

  out[i] = Number(BigInt(n) & 0b01111111n);

  while (BigInt(n) > 0b01111111n) {
    n = BigInt(n) / 128n - 1n;
    i -= 1;
    out[i] = Number(BigInt(n) | 0b10000000n);
  }

  return out.slice(i);
}

function encodeToTuple(n: any) {
  const tupleRepresentation = [];

  tupleRepresentation.push(Number(n & BigInt(0b0111_1111)));

  while (n > BigInt(0b0111_1111)) {
    n = n / BigInt(128) - BigInt(1);
    tupleRepresentation.unshift(
      Number((n & BigInt(0b0111_1111)) | BigInt(0b1000_0000)),
    );
  }

  return tupleRepresentation;
}

class Tag {
  static Body = 0;
  static Flags = 2;
  static Dune = 4;
  static Limit = 6;
  static OffsetEnd = 8;
  static Deadline = 10;
  static Pointer = 12;
  static HeightStart = 14;
  static OffsetStart = 16;
  static HeightEnd = 18;
  static Cap = 20;
  static Premine = 22;

  static Cenotaph = 254;

  static Divisibility = 1;
  static Spacers = 3;
  static Symbol = 5;
  static Nop = 255;

  static take(tag: any, fields: any) {
    return fields[tag];
  }

  static encode(tag: any, value: any, payload: any) {
    payload.push(varIntEncode(tag));
    if (tag == Tag.Dune) payload.push(encodeToTuple(value));
    else payload.push(varIntEncode(value));
  }
}

class PushBytes {
  bytes: any;
  constructor(bytes: any) {
    this.bytes = Buffer.from(bytes);
  }

  static fromSliceUnchecked(bytes: any) {
    return new PushBytes(bytes);
  }

  static fromMutSliceUnchecked(bytes: any) {
    return new PushBytes(bytes);
  }

  static empty() {
    return new PushBytes([]);
  }

  asBytes() {
    return this.bytes;
  }

  asMutBytes() {
    return this.bytes;
  }
}

const createScriptWithProtocolMsg = () => {
  // create an OP_RETURN script with the protocol message
  return new dogecore.Script().add("OP_RETURN").add(Buffer.from(IDENTIFIER));
};

// Construct the OP_RETURN dune script with encoding of given values
export function constructScript(
  pointer = undefined,
  cenotaph = null,
  edicts: Edict[] = [],
) {
  const payload: any = [];

  if (pointer !== undefined) {
    Tag.encode(Tag.Pointer, pointer, payload);
  }

  if (cenotaph) {
    Tag.encode(Tag.Cenotaph, 0, payload);
  }

  if (edicts && edicts.length > 0) {
    payload.push(varIntEncode(Tag.Body));

    const sortedEdicts = edicts.slice().sort((a, b) => {
      const idA = BigInt(a.id);
      const idB = BigInt(b.id);

      return idA < idB ? -1 : idA > idB ? 1 : 0;
    });
    let id = 0;

    for (const edict of sortedEdicts) {
      if (typeof edict.id === "bigint")
        payload.push(varIntEncode(edict.id - BigInt(id)));
      else payload.push(varIntEncode(edict.id - id));
      payload.push(varIntEncode(edict.amount));
      payload.push(varIntEncode(edict.output));
      id = edict.id;
    }
  }

  // Create script with protocol message
  const script = createScriptWithProtocolMsg();

  // Flatten the nested arrays in the tuple representation
  const flattenedTuple = payload.flat();

  // Push payload bytes to script
  for (let i = 0; i < flattenedTuple.length; i += MAX_SCRIPT_ELEMENT_SIZE) {
    const chunk = flattenedTuple.slice(i, i + MAX_SCRIPT_ELEMENT_SIZE);
    const push = PushBytes.fromSliceUnchecked(chunk);
    script.add(Buffer.from(push.asBytes()));
  }

  return script;
}

export { buildSendDuneTxs };
