import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCurrency, useDogePrice } from "@/contextHooks";
import { marketplaceApiV2 } from "@/lib/fetch";
import { formatLargeNumber, formatPercentage, usdToDoge } from "@/lib/numbers";
import { Currency, Drc20ActivityList, TickerElement } from "@/types";
import { handleError } from "@/utility";

type MarketCapResponseType = {
  drc20: number;
  collectibles: number;
  sum: number;
};

interface TickersReturnType {
  desktop: TickerElement[];
  mobile: TickerElement[];
}

export const useTickers = () => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();

  const [{ totalMarketCapUSD, dogiDominance, volume7dUSD }, setTickers] =
    useState<{
      totalMarketCapUSD: number;
      dogiDominance: number;
      volume7dUSD: number;
    }>({
      totalMarketCapUSD: 0,
      dogiDominance: 0,
      volume7dUSD: 0,
    });

  const fetchData = useCallback(async () => {
    console.log("useTickers - fetchData function");
    try {
      // Fetch market cap and dogi dominance data
      const [marketCapResponse, volumeResponse, dogiDominanceResponse] =
        await Promise.all([
          // this is in dollar
          marketplaceApiV2(false).get<MarketCapResponseType>(
            "/stats/marketcap/all",
          ),
          // this is in dollar
          marketplaceApiV2(false).get<MarketCapResponseType>(
            "/stats/volume/trading/7d",
          ),
          marketplaceApiV2(false).get<Drc20ActivityList>(
            "/drc20/list/activity?offset=0&limit=1&filterByTick=dogi&history=all",
          ),
        ]);

      // Calculate total market cap (in usd)
      const total = Math.floor(marketCapResponse.data.sum);

      // Calculate seven day volume (in usd)
      const volume = volumeResponse.data.sum || 0;

      // Calculate dogi dominance (in percentage based on usd)
      const dogiVolume = Math.floor(
        dogiDominanceResponse?.data.list[0].usdVolume || 0,
      );

      const dominance = (dogiVolume / total) * 100;

      // Set state
      setTickers({
        totalMarketCapUSD: total,
        volume7dUSD: volume,
        dogiDominance: dominance,
      });
    } catch (e: Error | unknown) {
      handleError(e);
      setTickers({
        totalMarketCapUSD: 0,
        dogiDominance: 0,
        volume7dUSD: 0,
      });
    }
  }, []);

  // Fetch data on mount and set an interval to fetch data every 5 minutes
  const intervalId = useRef<null | NodeJS.Timeout>(null);
  useEffect(() => {
    if (!intervalId.current) {
      fetchData();
    }

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    // Set a new interval
    intervalId.current = setInterval(() => fetchData(), 300000);

    // Cleanup on unmount
    return () => clearInterval(intervalId.current as NodeJS.Timeout);
  }, [fetchData]);

  const tickers: TickersReturnType = useMemo(() => {
    // Calculate & format the total volume in the selected currency
    const volume7dDOGE = usdToDoge(volume7dUSD, dogePrice);
    const volume = currency === Currency.USD ? volume7dUSD : volume7dDOGE;
    const formattedVolume = formatLargeNumber(volume);

    // Calculate & format the total market cap in the selected currency
    const totalMarketCapDOGE = usdToDoge(totalMarketCapUSD, dogePrice);
    const totalMarketCap =
      currency === Currency.USD ? totalMarketCapUSD : totalMarketCapDOGE;
    const formattedTotalMarketCap = formatLargeNumber(totalMarketCap);

    // Format the dogi dominance
    const formattedDogiDominance = formatPercentage(dogiDominance);

    return {
      desktop: [
        {
          label: "Market Cap",
          value: `${currency}${formattedTotalMarketCap}`,
        },
        {
          label: "Volume (7D)",
          value: `${currency}${formattedVolume}`,
        },
        {
          label: "Dominance - DOGI",
          value: formattedDogiDominance,
        },
      ],
      mobile: [
        {
          label: "MCap",
          value: formattedTotalMarketCap,
        },
        {
          label: "DOGI",
          value: formattedDogiDominance,
        },
      ],
    };
  }, [currency, dogePrice, volume7dUSD, totalMarketCapUSD, dogiDominance]);

  return tickers;
};
