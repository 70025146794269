import {
  Button,
  ImageWithFallback,
  ListItem,
  Separator,
  Spinner,
} from "@/components";
import {
  ALPHA_DISCOUNT,
  DRC_20_ORG_PAGE,
  LEGAL_TERMS,
  LISTING_MATCHING_FEE,
} from "@/constants";
import { useInfoModal } from "@/hooks";
// import { AccountDrc20Data } from "@/hooks";
import { AnimatedContent } from "@/modals/base";
import { ModalInfo } from "@/modals/modalInfo";
import { getIconForTick } from "@/utility";

interface Listing {
  amount: string;
  unitPrice: string;
  floorPrice: string;
  floorPriceDeltaPercentage: string;
}

interface Proceeds {
  potentialProceedsInUSD: string;
  potentialProceedsInDoge: string;
}

interface Fees {
  network: string;
  trading: string;
  tradingDiscountAlpha: string;
  service: string;
}

interface ConfirmViewProps {
  listing: Listing;
  proceeds: Proceeds;
  tick?: string;
  // tokenData: AccountDrc20Data;
  handleList: () => void;
  loading: boolean;
  fees: Fees;
  checkbox?: {
    visible: boolean;
    onClick: () => void;
    value: boolean;
  };
}

export const ConfirmView: React.FC<ConfirmViewProps> = ({
  listing,
  proceeds,
  handleList,
  tick,
  //   checkbox,
  loading,
  fees,
}) => {
  const {
    amount,
    unitPrice,
    // floorPrice,
    // floorPriceDeltaPercentage,
  } = listing;
  const { network, trading, tradingDiscountAlpha } = fees;
  const { potentialProceedsInUSD, potentialProceedsInDoge } = proceeds;
  const imageToken = getIconForTick(tick);

  const { info, infoVisible, handleOpenInfo, handleCloseInfo } = useInfoModal();

  if (!tick) return null;

  return (
    <AnimatedContent id="confirm_order">
      <div className="flex flex-1 flex-col space-y-4">
        <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
          {/** Amount */}
          <div className="flex flex-row items-center space-x-4">
            <ImageWithFallback
              image={imageToken}
              className="h-8 w-8 rounded-full"
            />
            <div className="flex flex-1 flex-row space-x-2">
              <div className="flex flex-1 flex-col space-y-1">
                <span className="text-xs uppercase text-text-tertiary">
                  Amount
                </span>
                <div className="flex flex-1 flex-row justify-between">
                  <span className="text-md uppercase text-text-primary">
                    {`${amount} ${tick}`}
                  </span>
                </div>
              </div>
              <div className="flex flex-1 flex-col space-y-1">
                <span className="w-full text-right text-xs uppercase text-text-tertiary">
                  Unit Price
                </span>
                <div className="flex flex-1 flex-row justify-between">
                  <span className="w-full text-right text-sm uppercase leading-tight text-text-tertiary md:text-md">
                    {unitPrice}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Separator />

          {/** Potential Proceeds */}
          <div className="flex flex-row items-center space-x-4">
            <ImageWithFallback
              image={`${DRC_20_ORG_PAGE}/images/dogecoin.svg`}
              className="h-8 w-8 rounded-full"
            />
            <div className="flex flex-1 flex-col space-y-1">
              <span className="text-xs uppercase text-text-tertiary">
                Potential Proceeds
              </span>
              <div className="flex flex-1 flex-row justify-between">
                <span
                  className="text-md uppercase text-text-primary"
                  dangerouslySetInnerHTML={{ __html: potentialProceedsInDoge }}
                />
                <span
                  className="text-md uppercase text-text-tertiary"
                  dangerouslySetInnerHTML={{
                    __html: `~ ${potentialProceedsInUSD}`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/** Fees */}
        <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary py-2 pl-2 pr-1">
          <div className="flex flex-col items-center space-y-2">
            <ListItem
              label="Network Fee"
              detail={network}
              withChevron={false}
              variant="info"
              icon="info"
              onClick={() =>
                handleOpenInfo(
                  "Network Fee",
                  "The Network Fee is the cost of processing the transaction on the blockchain.",
                )
              }
            />
            <Separator />
            <ListItem
              label={`Matching Fee (${LISTING_MATCHING_FEE}%)`}
              detail={trading}
              withChevron={false}
              icon="info"
              variant="info"
              onClick={() =>
                handleOpenInfo(
                  "Matching Fee",
                  `The Matching Fee (${LISTING_MATCHING_FEE}%) is calculated as a percentage of the total amount of the transaction.`,
                )
              }
            />
            <Separator />
            <ListItem
              label={`Alpha Phase Discount (${ALPHA_DISCOUNT}%)`}
              detail={`- ${tradingDiscountAlpha}`}
              withChevron={false}
              icon="info"
              variant="info"
              onClick={() =>
                handleOpenInfo(
                  "Alpha Phase Discount",
                  `the Alpha Phase discount (50%) is calculated based on the Matching Fee.`,
                )
              }
            />
            <Separator />
            <ListItem
              label="Service Fee"
              detail="100% Discount"
              withChevron={false}
              icon="info"
              variant="info"
              onClick={() =>
                handleOpenInfo(
                  "Service Fee",
                  "The Service Fee covers the cost of creating the transfer inscription required by the DRC-20 protocol.",
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col space-y-2">
        <Button
          size="large"
          variant="inverse"
          onClick={handleList}
          disabled={loading}
        >
          {loading ? <Spinner /> : "List Now"}
        </Button>
        <p className="text-center text-2xs italic text-text-tertiary">
          By clicking List Now, you confirm the accuracy of the input data and
          agree to the
          <span
            onClick={() => window.open(LEGAL_TERMS, "_blank")}
            className="ml-1 cursor-pointer underline"
          >
            {" "}
            Terms of Service
          </span>
          .
        </p>
      </div>

      <ModalInfo
        info={info}
        isVisible={infoVisible}
        onClose={handleCloseInfo}
      />
    </AnimatedContent>
  );
};
