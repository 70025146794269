import React, { useContext, useEffect, useMemo } from "react";
import { useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { AppHeader, AppHeaderDesktop, BottomTabNavigation } from "@/components";
import { useRefreshKey, useWalletManagement } from "@/contextHooks";
import { useLoginPrompt } from "@/contextHooks/useLoginPrompt";
import { useAppHeaderHeight, useCurrentAccount, useTickers } from "@/hooks";
import { UIContext } from "@/context";
import { cn } from "@/lib/utils";
import { useAppVersionManager } from "@/hooks/useAppVersionManager.ts";

// Define the tabs
const TABS = [
  { name: "Trade", icon: "paid", path: "/trading" },
  { name: "Chat", icon: "chat", path: "/", disabled: true },
  { name: "Launchpad", icon: "rocket_launch", path: "/", disabled: true },
  { name: "Inscribe", icon: "signature", path: "/", disabled: true },
  { name: "Account", icon: "person", path: "/account" },
];

// Define the links (Desktop)
const LINKS = [
  { name: "Trade", path: "/trading" },
  { name: "Chat", path: "/", disabled: true },
  { name: "Launchpad", path: "/", disabled: true },
  { name: "Inscribe", path: "/", disabled: true },
  { name: "Account", path: "/account" },
];

export const LayoutApp: React.FC = () => {
  const tickers = useTickers();
  const location = useLocation();
  const { appHeaderHeight } = useAppHeaderHeight();
  useAppVersionManager();
  const { isDeviceMobile } = useContext(UIContext);
  const navigate = useNavigate();
  const { pw } = useWalletManagement();
  const { address } = useCurrentAccount();
  const { refreshKey, updateRefreshKey } = useRefreshKey();
  const { loginPromptUI, isLoginPromptOpen, openLoginPrompt } =
    useLoginPrompt();

  /**
   * Actions
   */

  const handleRefresh = useCallback(() => {
    updateRefreshKey(refreshKey + 1);
  }, [updateRefreshKey, refreshKey]);

  /**
   * UI State
   */

  const showMobileTabNavigation = useMemo(() => {
    const pathFromTabs = TABS.map((tab) => tab.path);
    return ["/", ...pathFromTabs].includes(location.pathname);
  }, [location.pathname]);

  const showMobileAppHeader = useMemo(() => {
    return (
      showMobileTabNavigation ||
      (location.pathname.includes("/drc20") &&
        !location.pathname.includes("/account")) ||
      (location.pathname.includes("/dune") &&
        !location.pathname.includes("/account")) ||
      (location.pathname.includes("/collectible") &&
        !location.pathname.includes("/account")) ||
      location.pathname.includes("/account/collectible") ||
      location.pathname.includes("/account/drc20") ||
      location.pathname.includes("/account/dune") ||
      location.pathname.includes("/wallet")
    );
  }, [showMobileTabNavigation, location.pathname]);

  /**
   * Effects
   */

  // Controls when to prompt for credentials
  useEffect(() => {
    const isOnAccountScreen = location.pathname.includes("account");
    if (address && !pw && !isLoginPromptOpen && isOnAccountScreen) {
      openLoginPrompt(true, () => navigate("/trading"));
    }
  }, [
    address,
    isLoginPromptOpen,
    openLoginPrompt,
    location.pathname,
    pw,
    navigate,
  ]);

  return (
    <div className="relative flex h-dvh w-screen flex-col bg-background-primary">
      {/* Outlet and content should scroll within this container */}
      <div
        className="flex flex-1 flex-col overflow-y-auto"
        style={{ paddingTop: appHeaderHeight }}
      >
        {/* App Header (conditionally rendered & hidden via tailwind classes depending on device) */}
        {showMobileAppHeader && (
          <AppHeader
            tickers={tickers.mobile}
            onRefresh={handleRefresh}
            className={cn(!isDeviceMobile && "md:hidden")}
          />
        )}

        {/** App Header for Desktop (hidden via tailwind classes for mobile devicess) */}
        <AppHeaderDesktop
          links={LINKS}
          tickers={tickers.desktop}
          className={cn(isDeviceMobile ? "hidden" : "max-md:hidden")}
        />

        <Outlet key={refreshKey} />

        {/* Tab Navigation (conditionally rendered & hidden via tailwind classes for desktop devices) */}
        {showMobileTabNavigation && (
          <BottomTabNavigation
            tabs={TABS}
            className={cn(!isDeviceMobile && "md:hidden")}
          />
        )}
      </div>
      {isLoginPromptOpen && <>{loginPromptUI}</>}
    </div>
  );
};
