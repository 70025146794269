import { useCallback, useEffect, useState } from "react";

import { marketplaceApiV2 } from "@/lib/fetch";
import { CollectibleTradingData, Inscription } from "@/types";

type RequestParams = {
  [key: string]: any;
};

const fetchDoginals = async (
  address: string,
  params?: RequestParams,
): Promise<{
  inscriptions: Inscription[];
  total_inscriptions: number;
}> => {
  const response = await marketplaceApiV2(false, { ...params, address }).get<{
    balance: Inscription[];
    total_inscriptions: number;
  }>(`/doginals/balance`);

  return {
    inscriptions: response?.data.balance || [],
    total_inscriptions: response?.data.total_inscriptions || 0,
  };
};

export const fetchCollectiblesTradingData = async (
  symbols: string[],
): Promise<CollectibleTradingData[]> => {
  const response = await marketplaceApiV2(false).get<CollectibleTradingData[]>(
    `/doginals/trading/data?symbols=${symbols.join(",")}`,
  );
  return response?.data;
};

export type PendingCollectiblesBalance = {
  content: string | null;
  content_length: number;
  content_type: string;
  genesis_height: number;
  inscription_id: string;
  inscription_number: number;
  timestamp: number;
  tx_id: string;
  vout: number;
};

type PendingCollectiblesBalances = {
  address: string;
  balances: {
    sending: PendingCollectiblesBalance[];
    receiving: PendingCollectiblesBalance[];
  };
  allTxsFound: boolean;
};

const fetchPendingCollectiblesBalance = async (
  address: string,
  inscriptionIds?: string[],
  maxRetries: number = 15,
  intervalTime: number = 1500,
): Promise<PendingCollectiblesBalances> => {
  const response = await marketplaceApiV2(
    true,
  ).post<PendingCollectiblesBalances>(`/doginals/pending-balance/${address}`, {
    maxRetries,
    intervalTime,
    inscriptionIds,
  });
  return response?.data;
};

const useFetchDoginals = (
  address?: string,
  fetchPendingBalances: boolean = false,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | unknown>(undefined);
  const [pendingSendingCollectibles, setPendingSendingCollectibles] = useState<
    PendingCollectiblesBalance[]
  >([]);
  const [pendingReceivingCollectibles, setPendingReceivingCollectibles] =
    useState<PendingCollectiblesBalance[]>([]);
  const [isError, setIsError] = useState(false);
  const [collectibles, setCollectibles] = useState<Inscription[]>([]);

  const fetch = useCallback(async () => {
    if (!address) {
      setCollectibles([]);
      return;
    }

    let result = [] as Inscription[];
    let filteredSending = [] as PendingCollectiblesBalance[];
    let filteredReceiving = [] as PendingCollectiblesBalance[];
    try {
      setLoading(true);
      setError(undefined);
      setIsError(false);
      const { inscriptions } = await fetchDoginals(address);

      if (fetchPendingBalances) {
        const { balances } = await fetchPendingCollectiblesBalance(
          address,
          undefined,
          2,
          1500,
        );

        filteredSending = balances.sending.filter(
          (pending) =>
            !inscriptions.some(
              (inscription) =>
                inscription.inscription_id === pending.inscription_id,
            ),
        );

        filteredReceiving = balances.receiving.filter(
          (pending) =>
            !inscriptions.some(
              (inscription) =>
                inscription.inscription_id === pending.inscription_id,
            ),
        );
      }

      result = inscriptions;
    } catch (error: Error | unknown) {
      setIsError(true);
      setError(error);
    } finally {
      setCollectibles(result);
      setLoading(false);
      setPendingSendingCollectibles(filteredSending || []);
      setPendingReceivingCollectibles(filteredReceiving || []);
    }
  }, [address, fetchPendingBalances]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    getCollectibles: fetch,
    collectibles,
    loading,
    error,
    isError,
    pendingSendingCollectibles,
    pendingReceivingCollectibles,
  };
};

export { useFetchDoginals, fetchDoginals, fetchPendingCollectiblesBalance };
