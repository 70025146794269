import React from "react";

import { cn } from "@/lib/utils";

import { AnimatedClickable } from "@/components";

interface ToggleSwitchProps {
  value: string;
  icon: string;
  contentName?: string;
  disabled?: boolean;
  className?: string;
  onChange: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  value,
  icon,
  contentName = "",
  className,
  disabled = false,
  onChange,
}) => {
  const handleChange = () => !disabled && onChange();

  return (
    <AnimatedClickable
      key={value}
      className={cn(
        "relative flex h-8 items-center rounded-full bg-background-secondary",
        "hover:bg-background-tertiary",
        contentName ? "px-4 lg:h-10 lg:w-36" : "lg:h-10 lg:w-10",
        className,
      )}
      whileHover={{ scale: 1.1 }}
      onClick={handleChange}
    >
      {contentName && (
        <span
          className={cn(
            "text-sm",
            disabled
              ? "font-semibold text-text-disabled"
              : "font-semibold text-text-primary",
            "hidden flex-grow lg:inline-flex",
          )}
          style={{ marginRight: "10px" }}
        >
          {contentName}
        </span>
      )}
      <span
        className={cn(
          "material-symbols-rounded text-xl",
          disabled ? "text-text-disabled" : "text-text-primary",
          "lg:text-2xl",
        )}
        style={{ marginRight: "10px" }}
      >
        {icon}
      </span>

      <div
        className={cn(
          "absolute flex h-4 w-4 items-center justify-center rounded-full border-2 border-background-primary",
          "lg:h-6 lg:w-6",
          "lg:right -bottom-0.5 -right-0.5 lg:-bottom-1",
          disabled
            ? "bg-background-secondary text-text-disabled"
            : "bg-primary-500 text-white",
        )}
      >
        <span className="material-symbols-rounded text-2xs lg:text-sm">
          cached
        </span>
      </div>
    </AnimatedClickable>
  );
};
