import React, { useCallback } from "react";

import {
  Button,
  ListItem,
  PaymentItem,
  Separator,
  Spinner,
} from "@/components";
import {
  DOGE_LOGO,
  LEGAL_TERMS,
  BUYING_MATCHING_FEE_DUNES,
  ALPHA_DISCOUNT,
} from "@/constants";
import { useInfoModal } from "@/hooks";
import { ModalInfo } from "@/modals/modalInfo";
import { getIconForTick } from "@/utility";

import { AnimatedContent } from "../../base";

interface Fees {
  network: string;
  taker: string;
  takerDiscountAlpha: string;
}

interface Values {
  valueInUSD?: string;
  value: string;
  valueDetails?: string;
}

interface BuyViewProps {
  handleBuy: () => void;
  loading: boolean;
  tick?: string;
  fees: Fees;
  pay: Values;
  receive: Values;
}

export const BuyView: React.FC<BuyViewProps> = ({
  fees,
  pay,
  receive,
  tick,
  loading,
  handleBuy,
}: BuyViewProps) => {
  const { info, infoVisible, handleOpenInfo, handleCloseInfo } = useInfoModal();

  const handleOpenPaymentInfo = useCallback(() => {
    handleOpenInfo("Payment Info", [
      "The amounts displayed here are estimates based on your current inscription selections.",
      "The amount You Pay and You Receive therefore may vary from what is shown, contingent on the remaining availability of these inscriptions, the Dogecoin mempool, status of the network, etc.",
      "By proceeding, you agree to these terms.",
    ]);
  }, [handleOpenInfo]);

  return (
    <AnimatedContent id="buy">
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col space-y-2">
          <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
            <PaymentItem
              image={DOGE_LOGO}
              label="You Pay"
              value={pay.value}
              valueInUSD={pay.valueInUSD}
              onClick={handleOpenPaymentInfo}
            />
            <Separator />
            <PaymentItem
              image={getIconForTick(tick)}
              label="You Receive"
              value={receive.value}
              valueInUSD={receive.valueInUSD}
              valueDetails={receive.valueDetails}
              onClick={handleOpenPaymentInfo}
            />
          </div>
          <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary py-2 pl-2 pr-1">
            <div className="flex flex-col items-center space-y-2">
              <ListItem
                label="Network Fee"
                detail={fees.network}
                withChevron={false}
                variant="info"
                icon="info"
                onClick={() =>
                  handleOpenInfo(
                    "Network Fee",
                    "The Network Fee is the cost of processing the transaction on the blockchain.",
                  )
                }
              />
              <Separator />
              <ListItem
                label={`Matching Fee (${BUYING_MATCHING_FEE_DUNES}%)`}
                detail={fees.taker}
                withChevron={false}
                icon="info"
                variant="info"
                onClick={() =>
                  handleOpenInfo(
                    "Matching Fee",
                    `The Matching Fee (${BUYING_MATCHING_FEE_DUNES}%) is calculated as a percentage of the total amount of the transaction.`,
                  )
                }
              />
              <Separator />
              <ListItem
                label={`Alpha Phase Discount (${ALPHA_DISCOUNT}%)`}
                detail={fees.takerDiscountAlpha}
                withChevron={false}
                icon="info"
                variant="info"
                onClick={() =>
                  handleOpenInfo(
                    "Alpha Phase Discount",
                    `the Alpha Phase discount (50%) is calculated based on the Matching Fee.`,
                  )
                }
              />
            </div>
          </div>{" "}
        </div>
        <div className="flex w-full flex-col space-y-2">
          <Button
            size="large"
            variant="inverse"
            onClick={handleBuy}
            disabled={loading}
          >
            {loading ? <Spinner size={24} /> : "Buy Now"}
          </Button>
          <p className="text-center text-2xs italic text-text-tertiary">
            By clicking Buy Now, you confirm the accuracy of the input data and
            agree to the
            <span
              onClick={() => window.open(LEGAL_TERMS, "_blank")}
              className="ml-1 cursor-pointer underline"
            >
              {" "}
              Terms of Service
            </span>
            .
          </p>
        </div>
      </div>

      <ModalInfo
        info={info}
        isVisible={infoVisible}
        onClose={handleCloseInfo}
      />
    </AnimatedContent>
  );
};
