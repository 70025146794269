import { ChangeRange } from "@/types";

const APP_VERSION = import.meta.env.VITE_APP_VERSION || "0.0.1";

// Security Preferences
const PIN_LENGTH = 6;

export const WONKY_ORD_URL = "https://wonky-ord-v2.dogeord.io/";

// endpoints
const WONKY_ORD_API = import.meta.env.VITE_WONKY_ORD_API || WONKY_ORD_URL;
const WALLET_API =
  import.meta.env.VITE_WALLET_API || "https://wallet-api.dogeord.io";
const MARKETPLACE_API_V2 =
  import.meta.env.VITE_MARKETPLACE_API_V2 ||
  "https://marketplace-api-v2-v.dogeord.io";

const TX_BROADCASTER =
  import.meta.env.VITE_TX_BROADCASTER || MARKETPLACE_API_V2;
const D20_API_V2 =
  import.meta.env.VITE_D20_API_V2 || "https://d20-api2.dogeord.io";
const DRC_20_ORG_PAGE =
  import.meta.env.VITE_DRC_20_ORG_PAGE || "https://drc-20.org";
const SDOGGS_API_V2 =
  import.meta.env.VITE_SDOGGS_API_V2 ||
  "https://dunes-marketplace-api.sdoggs.exchange";
const TX_BROADCASTER_DUNES =
  import.meta.env.VITE_TX_BROADCASTER_DUNES || SDOGGS_API_V2;

// numbers
const ONE_DOGE_IN_SHIBES = 100_000_000;
const FALLBACK_DOGE_PRICE = 0.15;
// Fees a miner get if a tx is used (0.3 doge is high, but by that we ensure it wents through even in high traffic
// times)
const MINER_FEE = 30_000_000;
const DUST_AMOUNT_AND_MIN_PRICE = 100_000;
const INSCRIPTION_SATS = 100_000;
// THIS NEEDS TO BE IN SYNC WITH THE MARKETPLACE API VALUE
const SAFE_DUST_AMOUNT =
  Number(import.meta.env.VITE_SAFE_DUST_AMOUNT) ||
  DUST_AMOUNT_AND_MIN_PRICE * 2.5;
const REORG_SAFE_MIN_CONFIRMATIONS = 12;
// DONT ADD MORE THAN 20, THIS IS THE MAXIMUM THE MARKETPLACE API for activities can handle
const PAGINATION_LIMIT = 20;
const DRC20_LISTINGS_PAGINATION_LIMIT = 25;
const DUNES_LISTINGS_PAGINATION_LIMIT = 25;
const DOGINALS_LISTINGS_PAGINATION_LIMIT = 25;

// external links
const MOONPAY_BUY_DOGE_LINK =
  import.meta.env.VITE_MOONPAY_BUY_DOGE_LINK ||
  "https://www.moonpay.com/buy/doge";

// socials
const SOCIAL_X =
  import.meta.env.VITE_SOCIAL_X || "https://twitter.com/verydogelabs";
const SOCIAL_DISCORD =
  import.meta.env.VITE_SOCIAL_DISCORD || "http://discord.gg/fjtwfDFHFr";

const DOGE_LOGO = "/dogecoin-doge-logo.svg";
const DOGE_LABS_LOGO_DESKTOP = "/logo_desktop.png";

// legal
const LEGAL_TERMS =
  import.meta.env.VITE_LEGAL_TERMS || "https://terms.doge-labs.com";
const LEGAL_PRIVACY =
  import.meta.env.VITE_LEGAL_PRIVACY || "https://privacy.doge-labs.com";

const SUPPORT =
  import.meta.env.VITE_SUPPORT ||
  "https://discord.gg/doge-labs-1105520236733681827";

enum NetworkFeeType {
  Slow = "slow",
  Average = "average",
  Fast = "fast",
}

interface NetworkFeeOption {
  value: NetworkFeeType;
  speed: string;
  duration: string;
  feeVb: number;
}

const NETWORK_FEES: NetworkFeeOption[] = [
  {
    value: NetworkFeeType.Slow,
    speed: "Slow",
    duration: "~ 10 minutes",
    feeVb: 48828,
  },
  {
    value: NetworkFeeType.Average,
    speed: "Average",
    duration: "~ 6 minutes",
    feeVb: 97656,
  },
  {
    value: NetworkFeeType.Fast,
    speed: "Fast",
    duration: "~ 2 minutes",
    feeVb: 292968,
  },
];
const LOW_NETWORK_FEE_RATE = NETWORK_FEES[0].feeVb;
const AVERAGE_NETWORK_FEE_RATE = NETWORK_FEES[1].feeVb;
const HIGH_NETWORK_FEE_RATE = NETWORK_FEES[2].feeVb;

// Service Fee
// ensure this is synced with the service fee in the marketplace API
// If set it must be > Dust Amount
const SERVICE_FEE_SATS = Number(import.meta.env.VITE_SERVICE_FEE_SATS) || 0;
const SERVICE_FEE_ADDRESS =
  import.meta.env.VITE_SERVICE_FEE_ADDRESS ||
  "DA68rDknfN4L2R2xJXc1fexuACaWMWLKR8";

// Trading Fee
// ensure this is synced with the trading fee in the marketplace API
// trading fee address is add in the backend
const MATCHING_FEE = 1.33; // percentage
const LISTING_MATCHING_FEE = 1.33; // percentage
const LISTING_MATCHING_FEE_DUNES = 3.99; // percentage
const BUYING_MATCHING_FEE = 2.33; // percentage
const BUYING_MATCHING_FEE_DUNES = 4.99; // percentage
const ALPHA_DISCOUNT = 50; // percentage
const LISTING_MATCHING_FEE_AFTER_ALPHA_DISCOUNT =
  LISTING_MATCHING_FEE / ((LISTING_MATCHING_FEE / 100) * ALPHA_DISCOUNT);
const LISTING_MATCHING_FEE_DUNES_AFTER_ALPHA_DISCOUNT =
  LISTING_MATCHING_FEE_DUNES /
  ((LISTING_MATCHING_FEE_DUNES / 100) * ALPHA_DISCOUNT);
const BUYING_MATCHING_FEE_AFTER_ALPHA_DISCOUNT =
  LISTING_MATCHING_FEE / ((BUYING_MATCHING_FEE / 100) * ALPHA_DISCOUNT);
const BUYING_MATCHING_FEE_DUNES_AFTER_ALPHA_DISCOUNT =
  BUYING_MATCHING_FEE_DUNES /
  ((BUYING_MATCHING_FEE_DUNES / 100) * ALPHA_DISCOUNT);

// Firebase
const FIREBASE_CONFIG_DEV = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY_DEV,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN_DEV,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID_DEV,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET_DEV,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID_DEV,
  appId: import.meta.env.VITE_FIREBASE_APP_ID_DEV,
  // measurementId: import.meta.env.VITE_FIREBASE_MEASUREMEND_ID_DEV,
};

const FIREBASE_CONFIG_PROD = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY_PROD,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN_PROD,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID_PROD,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET_PROD,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID_PROD,
  appId: import.meta.env.VITE_FIREBASE_APP_ID_PROD,
  // measurementId: import.meta.env.VITE_FIREBASE_MEASUREMEND_ID_PROD,
};

const FIREBASE_CONFIG =
  import.meta.env.MODE === "production"
    ? FIREBASE_CONFIG_PROD
    : FIREBASE_CONFIG_DEV;

const LOCAL_STORAGE_MESSAGING_TOKEN_KEY_DEV =
  "dogemp:firebase-messaging-token-dev";
const LOCAL_STORAGE_MESSAGING_TOKEN_KEY_PROD =
  "dogemp:firebase-messaging-token-prod";

const FEATURE_ACTIVATION_DUNES = import.meta.env.VITE_FEATURE_DUNES || false;

const VOLUME_LIMIT_TO_CONSIDER_ITEM_IN_DOGE = 2000;
// ignore tokens with marketcap > 10,000,000,000 DOGE (~ $1,000,000,000)
const MAX_MARKETCAP_VALUE_TO_CONSIDER_ITEM_IN_DOGE = 10_000_000_000;
const MAX_MARKETCAP_IN_SHIBES_FOR_VOLUME_COMPARISON = 10_000_000_000_000_000;
const MAX_VOLUME_IN_DOGE_FOR_VOLUME_COMPARISON = 1000000;
export const ACCOUNT_OVERALL_VOLUME_RANGE = ChangeRange.ALL;

// Local Storage key names
const HAS_COMPLETED_WALLET_SETUP_KEY = "has-completed-wallet-setup";
const PWA_LOCAL_STORAGE_KEY = "pwa";
const USER_WALLET_KEY = "user-wallet";
const USER_SECURITY_PREFERENCE_KEY = "user-security-preference";
const CONTENT_TYPE_KEY = "content-type";
const PENDING_INSCRIPTIONS_KEY = "pending-inscriptions";
const CURRENT_APP_VERION_KEY = "current-app-version";

export {
  PIN_LENGTH,
  APP_VERSION,
  HAS_COMPLETED_WALLET_SETUP_KEY,
  PWA_LOCAL_STORAGE_KEY,
  USER_WALLET_KEY,
  USER_SECURITY_PREFERENCE_KEY,
  CONTENT_TYPE_KEY,
  PENDING_INSCRIPTIONS_KEY,
  CURRENT_APP_VERION_KEY,
  WALLET_API,
  WONKY_ORD_API,
  MARKETPLACE_API_V2,
  ONE_DOGE_IN_SHIBES,
  FALLBACK_DOGE_PRICE,
  REORG_SAFE_MIN_CONFIRMATIONS,
  TX_BROADCASTER,
  TX_BROADCASTER_DUNES,
  MOONPAY_BUY_DOGE_LINK,
  NETWORK_FEES,
  LOW_NETWORK_FEE_RATE,
  AVERAGE_NETWORK_FEE_RATE,
  HIGH_NETWORK_FEE_RATE,
  SOCIAL_X,
  SOCIAL_DISCORD,
  LEGAL_TERMS,
  LEGAL_PRIVACY,
  SUPPORT,
  NetworkFeeType,
  FIREBASE_CONFIG,
  LOCAL_STORAGE_MESSAGING_TOKEN_KEY_DEV,
  LOCAL_STORAGE_MESSAGING_TOKEN_KEY_PROD,
  SERVICE_FEE_SATS,
  SERVICE_FEE_ADDRESS,
  MATCHING_FEE,
  LISTING_MATCHING_FEE,
  LISTING_MATCHING_FEE_DUNES,
  BUYING_MATCHING_FEE,
  BUYING_MATCHING_FEE_DUNES,
  LISTING_MATCHING_FEE_AFTER_ALPHA_DISCOUNT,
  LISTING_MATCHING_FEE_DUNES_AFTER_ALPHA_DISCOUNT,
  BUYING_MATCHING_FEE_AFTER_ALPHA_DISCOUNT,
  BUYING_MATCHING_FEE_DUNES_AFTER_ALPHA_DISCOUNT,
  ALPHA_DISCOUNT,
  DRC_20_ORG_PAGE,
  D20_API_V2,
  SDOGGS_API_V2,
  MINER_FEE,
  DUST_AMOUNT_AND_MIN_PRICE,
  SAFE_DUST_AMOUNT,
  DOGE_LOGO,
  DOGE_LABS_LOGO_DESKTOP,
  PAGINATION_LIMIT,
  DRC20_LISTINGS_PAGINATION_LIMIT,
  DOGINALS_LISTINGS_PAGINATION_LIMIT,
  INSCRIPTION_SATS,
  DUNES_LISTINGS_PAGINATION_LIMIT,
  FEATURE_ACTIVATION_DUNES,
  VOLUME_LIMIT_TO_CONSIDER_ITEM_IN_DOGE,
  MAX_MARKETCAP_VALUE_TO_CONSIDER_ITEM_IN_DOGE,
  MAX_MARKETCAP_IN_SHIBES_FOR_VOLUME_COMPARISON,
  MAX_VOLUME_IN_DOGE_FOR_VOLUME_COMPARISON,
};

export type { NetworkFeeOption };
