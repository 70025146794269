import React, { useMemo } from "react";

import { ListItem, Spinner } from "@/components";
import { useFetchDune } from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { AllDunesData } from "@/types";

interface TabTokenDetailsProps {
  tick: string | undefined;
  tokenData: AllDunesData | undefined;
}

export const TabTokenDetails: React.FC<TabTokenDetailsProps> = ({
  tick,
  tokenData,
}) => {
  const { loading } = useFetchDune();
  const dataDetail = useMemo(() => {
    if (!tokenData) return [];

    const { createdAt, maxSupply, holders, rank } = tokenData;

    return [
      { label: "Deployed Time", value: createdAt },
      {
        label: "Supply",
        value: maxSupply
          ? formatNumber({
              value: maxSupply,
              type: NumberFormatType.Large_Number,
            })
          : 0,
      },
      {
        label: "Owners",
        value: holders
          ? formatNumber({
              value: holders,
              type: NumberFormatType.Large_Number,
            })
          : 0,
      },
      { label: "Deploy Number", value: rank ?? "N/A" },
    ];
  }, [tokenData]);

  if (loading) {
    return (
      <div className="flex h-96 flex-1 flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mx-auto flex max-w-2xl flex-1 flex-col p-4">
      <div className="flex flex-1 flex-col space-y-4">
        <div className="text-center text-md font-bold text-text-primary">{`${tick?.toUpperCase()} Details`}</div>
        <div className="flex flex-col rounded-lg bg-background-primary/50 p-2 2xl:bg-background-secondary">
          {dataDetail.map(({ label, value }: any) => (
            <ListItem key={label} label={label} detail={value} />
          ))}
        </div>
      </div>
    </div>
  );
};
