import {
  CollectionListingProps,
  Currency,
  DoginalListing,
  ListingStatus,
  PendingCollectibleInscription,
  CollectiblePendingActionType,
} from "@/types";
import { calculateFloorDifference } from "@/utility/calculateFloorDifference.ts";
import { getFormattedValue } from "@/utility/table.ts";

export type ListingIdentifier = "offerId" | "inscriptionId";

export const createExtendedCollectibleListings = <T extends ListingIdentifier>({
  listings,
  selectedListings,
  floorPrice,
  pendingInscriptions,
  onSelectListing,
  onNavigateToListing,
  currency,
  dogePrice,
  identifier,
  checkIfUserIsSeller,
}: {
  listings: DoginalListing[];
  selectedListings: string[];
  floorPrice: number | null;
  pendingInscriptions?: PendingCollectibleInscription[];
  onSelectListing: (id: string) => void;
  onNavigateToListing?: (inscriptionId: string) => void;
  currency: Currency;
  dogePrice: number;
  identifier: T;
  checkIfUserIsSeller?: (address: string) => boolean;
}): CollectionListingProps[] => {
  return listings.map((listing: DoginalListing) => {
    const {
      collectionSymbol,
      listed,
      doginalOffer,
      doginalCollection,
      createdAt,
      price,
      inscriptionId,
      inscriptionNumber,
      lastSale,
      name,
      imageURI,
      owner,
      isReceivingPending,
      isSendingPending,
    } = listing;

    const userIsSeller =
      checkIfUserIsSeller?.(doginalOffer.address || "") ?? false;

    const floorDifferencePercentage = calculateFloorDifference(
      price,
      floorPrice || 0,
    );

    const { value: totalPriceForCurrency } = getFormattedValue(
      price,
      dogePrice,
      currency,
      true,
    );

    // const isLoading = buyItem.isLoading;
    const isLoading = false;
    const selectId =
      identifier === "offerId" ? doginalOffer.offerId || "" : inscriptionId;

    const isSelected =
      identifier === "offerId"
        ? Boolean(
            doginalOffer.offerId &&
              selectedListings.includes(doginalOffer.offerId),
          )
        : selectedListings.includes(inscriptionId);

    const pendingType = pendingInscriptions?.find(
      (actionedInscription) =>
        actionedInscription.inscriptionId === inscriptionId,
    )?.action;

    const getIsPending = () => {
      if (pendingType === CollectiblePendingActionType.Delist) {
        return !listed;
      } else {
        return isReceivingPending || isSendingPending;
      }
    };

    const status = doginalOffer.status || ListingStatus.Unlisted;

    return {
      collectionSymbol,
      collectionName: doginalCollection.name,
      offerId: doginalOffer.offerId || "",

      name,
      inscriptionId,
      inscriptionNumber,
      imageURI,

      price,
      priceForCurrency: totalPriceForCurrency,
      lastSalePrice: lastSale.price,

      listed,
      status,
      address: doginalOffer.address || "",
      createdAt,

      isLoading,
      isSelected,
      // onBuy: () => onBuyListing(offerId),
      onBuy: () => {},
      onNavigateToListing: () => onNavigateToListing?.(inscriptionId),
      onAction: () => onSelectListing(selectId),
      floorDifferencePercentage,

      currency,
      currentDogePrice: dogePrice,

      doginalCollection,
      doginalOffer,
      lastSale,
      owner,
      floorPrice,
      isPending: getIsPending(), // for disabling the action button (+) on each listing card
      isReceivingPending,
      isSendingPending,

      userIsSeller,
      isSelectable: status === ListingStatus.Listed && !!doginalOffer.offerId,
    };
  });
};
