import React, { useMemo } from "react";

import { cn } from "@/lib/utils";
import { getIconForTick } from "@/utility";

import { AnimatedClickable, ImageWithFallback } from "@/components";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";

interface ActivityCardDuneProps {
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  title: string;
  valuePrimary: string;
  valuePrimarySuffix?: string;
  valueSecondary: string;
  valueSecondarySuffix?: string;
  valueSecondaryIsChange?: boolean;
  tick: string;
  includeIndicatorSymbol?: boolean;
}

export const ActivityCardDune: React.FC<ActivityCardDuneProps> = ({
  tick,
  title,
  valuePrimary,
  valuePrimarySuffix,
  valueSecondary,
  valueSecondarySuffix,
  // this is ugly but we would need to refactor the whole component to make cleaner
  valueSecondaryIsChange = true,
  className,
  style,
  onClick,
  includeIndicatorSymbol = false,
}) => {
  const image = useMemo(() => getIconForTick(tick), [tick]);
  const suffixClassName =
    "flex justify-center items-center !ml-1 rounded-full bg-background-tertiary/50 px-2 py-0.5 text-2xs font-bold text-text-tertiary md:!ml-2 md:text-xs";

  return (
    <AnimatedClickable
      onClick={onClick}
      className={cn(
        "flex flex-shrink-0 h-full w-full cursor-pointer flex-col md:space-y-2 truncate rounded-lg border-0.5 border-border-primary bg-background-secondary p-3 w-max-[150px]",
        className,
      )}
      style={style}
      whileHover={{ scale: 1.02 }}
    >
      <div className="flex flex-row items-center space-x-2">
        <ImageWithFallback
          image={image}
          className="h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10"
        />
        <div className="flex flex-row items-center space-x-2">
          <span className="text-md font-bold text-text-primary md:text-lg lg:text-xl">{title}</span>
        </div>
      </div>
      
      <div className="flex flex-1 flex-col justify-end space-y-2">
        <div className="flex">
          <span
            className="text-sm font-semibold text-text-primary md:text-md lg:text-lg"
            dangerouslySetInnerHTML={{ __html: valuePrimary }}
          />

          {valuePrimarySuffix && (
            <span className={suffixClassName}>{valuePrimarySuffix}</span>
          )}
        </div>

        <div className="flex items-center justify-start space-x-2">
          {valueSecondaryIsChange ? (
            <ChangeIndicator
              includeArrow={includeIndicatorSymbol}
              change={valueSecondary}
              className="text-xs font-semibold md:text-sm lg:text-md"
            />
          ) : (
            <span className="text-xs font-semibold text-text-primary md:text-sm lg:text-md">
              {valueSecondary}
            </span>
          )}

          {valueSecondarySuffix && (
            <span className={suffixClassName}>{valueSecondarySuffix}</span>
          )}
        </div>
      </div>
    </AnimatedClickable>
  );
};
