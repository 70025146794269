import { useCallback, useState } from "react";

import {
  FEATURE_ACTIVATION_DUNES,
  ACCOUNT_OVERALL_VOLUME_RANGE,
  ONE_DOGE_IN_SHIBES,
} from "@/constants";
import { useDogePrice } from "@/contextHooks";
import { sdoggsApiV2 } from "@/lib/fetch";
import {
  AllDunesData,
  ChangeRange,
  CollectionData,
  Currency,
  DunesBalanceResponseData,
  DunesOverviewDetail,
  DunesOverviewResponseData,
  RequestParams,
} from "@/types";
import { DunesTickData } from "@/types/dunesList";
import { getValueForCurrency, handleError } from "@/utility";
import { filterTokenByMarketCapRule } from "@/utility/filterTokenByMarketCapRule.ts";
import { filterTokenByVolumeRule } from "@/utility/filterTokenByVolumeRule.ts";
import isString from "lodash/isString";
import { formatBigValue, formatBigValueExact } from "@/lib/numbers";

const fetchDunes = async (
  address: string,
  params?: RequestParams,
): Promise<{ dunes: DunesOverviewResponseData[] }> => {
  if (FEATURE_ACTIVATION_DUNES !== "true") {
    return { dunes: [] };
  }
  const response = await sdoggsApiV2(true, { ...params, address }).get(
    `/dunes/balance`,
  );

  const dunes_raw: DunesBalanceResponseData[] = response.data.balances.dunes;

  const dunes: DunesOverviewResponseData[] = dunes_raw.map((dune) => {
    let rec_amount = 0;
    let send_amount = 0;
    dune.pending?.receiving?.forEach((rec) => {
      rec_amount += Number(rec.amount);
    });
    dune.pending?.sending?.forEach((send) => {
      send_amount += Number(send.amount);
    });
    return {
      balances: dune.balances,
      divisibility: dune.divisibility,
      dune: dune.dune,
      symbol: dune.symbol,
      total_balance: dune.total_balance,
      total_outputs: dune.total_outputs,
      pending: {
        sending: send_amount.toString(),
        receiving: rec_amount.toString(),
      },
    };
  });

  return { dunes };
};

export const fetchDunesTicks = async (
  ticks: string[],
): Promise<DunesTickData[]> => {
  if (FEATURE_ACTIVATION_DUNES !== "true") {
    return [];
  }

  if (ticks.length === 0) {
    return [];
  }

  const response = await sdoggsApiV2(false).get<DunesTickData[]>(
    `/dunes/data?ticks=${ticks.join(",")}`,
  );
  return response?.data;
};

const calculateMarketCapDunes = (
  data: DunesTickData | CollectionData | AllDunesData,
): number => {
  let currentSupply: number;
  if ("supply" in data) {
    // CollectionData
    currentSupply = data.supply;
  } else {
    // Drc20TickData (number) | AllDunesData (string)
    currentSupply = isString(data.currentSupply)
      ? parseFloat(data.currentSupply)
      : data.currentSupply;
  }
  return currentSupply * data.floorPrice;
};

// hook to fetch any Dune tokens for an address and each token's details
const useFetchDunes = (
  address?: string,
): {
  getDunes: () => Promise<void>;
  dunes: DunesOverviewDetail[];
  loading: boolean;
} => {
  const { dogePrice } = useDogePrice();
  const [loading, setLoading] = useState(false);
  const [dunes, setDunes] = useState<DunesOverviewDetail[]>([]);

  const getDunes = useCallback(async () => {
    if (!address || FEATURE_ACTIVATION_DUNES !== "true" || !dogePrice) {
      setDunes([]);
      return;
    }

    setLoading(true);

    let result = [] as DunesOverviewDetail[];

    try {
      // if necessary, we could fetch additonal data about dune token in parallel like in useFetchDrc20.ts
      const data = await fetchDunes(address, {
        show_utxos: false,
        show_all: true,
      });
      const dunes: DunesOverviewResponseData[] = data.dunes;

      const ticks = dunes.map((dune: DunesOverviewResponseData) => dune.dune);
      const ticksDetails = await fetchDunesTicks(ticks);

      const fullOverviewPromises = dunes.map(
        async (dune: DunesOverviewResponseData) => {
          const { pending } = dune;
          const pendingSend = formatBigValue(
            Number(pending.sending),
            dune.divisibility,
          );
          const pendingRec = formatBigValue(
            Number(pending.receiving),
            dune.divisibility,
          );
          const tick = dune.dune;
          const amount =
            dune.total_balance < 0
              ? 0
              : formatBigValueExact(dune.total_balance, dune.divisibility);

          try {
            let floorPrice = 0;
            let changePercent = 0;
            const currentTickData = ticksDetails.find(
              (tickData) => tickData.tick == tick,
            );
            if (currentTickData) {
              floorPrice = currentTickData.floorPrice;
              changePercent = currentTickData.twentyFourHourChangePercent;
            }

            const priceData = {
              floorPrice: {
                doge: getValueForCurrency(
                  currentTickData?.floorPrice ?? 0,
                  Currency.DOGE,
                  dogePrice,
                ),
                usd: getValueForCurrency(
                  currentTickData?.floorPrice ?? 0,
                  Currency.USD,
                  dogePrice,
                ),
              },
              twentyFourHourChangePercent:
                currentTickData?.twentyFourHourChangePercent,
              weeklyChangePercent: currentTickData?.weeklyChangePercent,
              weeklyVolume: {
                doge: getValueForCurrency(
                  currentTickData?.weeklyVolume ?? 0,
                  Currency.DOGE,
                  dogePrice,
                ),
                usd: getValueForCurrency(
                  currentTickData?.weeklyVolume ?? 0,
                  Currency.USD,
                  dogePrice,
                ),
              },
              volume: {
                doge: getValueForCurrency(
                  currentTickData?.volume ?? 0,
                  Currency.DOGE,
                  dogePrice,
                ),
                usd: getValueForCurrency(
                  currentTickData?.volume ?? 0,
                  Currency.USD,
                  dogePrice,
                ),
              },
            };

            const floorPriceDoge = floorPrice / ONE_DOGE_IN_SHIBES;
            const floorPriceUsd = floorPriceDoge * dogePrice;

            const volumeRangeForMarketCapDecision =
              ACCOUNT_OVERALL_VOLUME_RANGE.toString() ==
              ChangeRange.SEVEN_DAYS.toString()
                ? currentTickData?.weeklyVolume || 0
                : currentTickData?.volume || 0;
            const setValueToZeroByMarketCapRule = filterTokenByMarketCapRule(
              currentTickData?.marketCap || 0,
              volumeRangeForMarketCapDecision,
              currentTickData?.tick || "",
            );
            // if weekly volume is used, it needs to be adjusted in the backend, too at:
            const setValueToZeroByVolumeRule = filterTokenByVolumeRule(
              volumeRangeForMarketCapDecision,
            );
            const valueDollar =
              setValueToZeroByMarketCapRule || setValueToZeroByVolumeRule
                ? 0
                : floorPriceUsd * amount;
            const valueDoge =
              setValueToZeroByMarketCapRule || setValueToZeroByVolumeRule
                ? 0
                : floorPriceDoge * amount;

            // Calculate previous value using the changePercent
            const previousDollar = valueDollar / (1 + changePercent / 100);
            const previousDoge = valueDoge / (1 + changePercent / 100);

            const changeDollar = valueDollar - previousDollar;
            const changeDoge = valueDoge - previousDoge;

            return {
              tick,
              amount,
              pendingSend,
              pendingRec,
              valueDollar,
              valueDoge,
              changeDollar,
              changeDoge,
              priceData,
            };
          } catch (e: Error | unknown) {
            handleError(e);
            return null;
          }
        },
      );

      const fullOverview = (await Promise.all(fullOverviewPromises)).filter(
        Boolean,
      );

      // filtering out nulls
      result = fullOverview.filter(
        (item) => item !== null,
      ) as DunesOverviewDetail[];
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setDunes(result);
      setLoading(false);
    }
  }, [address, dogePrice]);

  return { getDunes, dunes, loading };
};

export type { DunesOverviewDetail, RequestParams, DunesTickData };
export { useFetchDunes, fetchDunes, calculateMarketCapDunes };
