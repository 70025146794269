import { marketplaceApiV2 } from "@/lib/fetch";
import {
  Currency,
  TimeFrames,
  CollectionData,
  CollectionList,
  DoginalListing,
} from "@/types";
import { handleError } from "@/utility";
import { FetchActivityData } from "@/types/watchlist.ts";

const API_ENDPOINT = "/doginals/list/activity";

const createLookupMap = <T extends keyof CollectionData>(
  data: CollectionList["collections"],
  key: T,
): Record<string, number> => {
  return data.reduce(
    (acc: Record<string, number>, item: CollectionData) => {
      if (typeof item[key] === "number") {
        acc[item.symbol] = item[key] as number;
      } else {
        acc[item.symbol] = 0;
      }
      return acc;
    },
    {} as Record<string, number>,
  );
};

export const fetchDoginalTokens = async ({
  action,
  offset,
  limit,
  history,
  sortOrder,
  sortParam,
  currency,
  dogePrice,
  collectionSymbol,
  filter,
  cachebreaker = false,
}: FetchActivityData): Promise<CollectionData[]> => {
  try {
    const params = {
      action,
      offset,
      limit,
      history: history ? history : TimeFrames.ALL,
      sortOrder,
      ...(sortParam ? { sortParam } : {}),
      ...(collectionSymbol
        ? { collectionSymbol }
        : filter
          ? { collectionSymbol: filter }
          : {}),
    };

    const response = await marketplaceApiV2(cachebreaker).get<CollectionList>(
      API_ENDPOINT,
      {
        params: { ...params },
      },
    );

    const data = response.data.collections || [];

    const changeMaps = {
      "24h": createLookupMap(data, "change24h"),
      "7d": createLookupMap(data, "change7d"),
      "30d": createLookupMap(data, "change30d"),
      all: createLookupMap(data, "change"),
    };

    const volumeMaps = {
      "24h": createLookupMap(data, "volume24h"),
      "7d": createLookupMap(data, "volume7d"),
      "30d": createLookupMap(data, "volume30d"),
      all: createLookupMap(data, "volume"),
    };

    const priceMaps = {
      "24h": createLookupMap(data, "floorPrice24h"),
      "7d": createLookupMap(data, "floorPrice7d"),
      "30d": createLookupMap(data, "floorPrice30d"),
      all: createLookupMap(data, "floorPrice"),
    };

    const salesMap = {
      "24h": createLookupMap(data, "sales24h"),
      "7d": createLookupMap(data, "sales7d"),
      "30d": createLookupMap(data, "sales30d"),
      all: createLookupMap(data, "sales"),
    };

    const mapItem = (item: CollectionData, index: number) => {
      const {
        collectionSymbol,
        name,
        floorPrice,
        holders,
        supply,
        marketplaceFeatured,
        dogepadCollection,
      } = item;

      const fdvInShibs = floorPrice ? (floorPrice || 0) * (supply || 0) : 0;

      return {
        ...item,
        ...{
          id: collectionSymbol,
          name: name,
          fdv: fdvInShibs,
          rank: index + 1 + (offset || 0),
          price: priceMaps["all"][collectionSymbol],
          price24h: priceMaps["24h"][collectionSymbol],
          price7d: priceMaps["7d"][collectionSymbol],
          price30d: priceMaps["30d"][collectionSymbol],
          change: changeMaps["all"][collectionSymbol],
          change24h: changeMaps["24h"][collectionSymbol],
          change7d: changeMaps["7d"][collectionSymbol],
          change30d: changeMaps["30d"][collectionSymbol],
          volume: volumeMaps["all"][collectionSymbol],
          volume24h: volumeMaps["24h"][collectionSymbol],
          volume7d: volumeMaps["7d"][collectionSymbol],
          volume30d: volumeMaps["30d"][collectionSymbol],
          sales: salesMap["all"][collectionSymbol],
          sales24h: salesMap["24h"][collectionSymbol],
          sales7d: salesMap["7d"][collectionSymbol],
          sales30d: salesMap["30d"][collectionSymbol],
          currentDogePrice: dogePrice,
          holders: holders || 0,
          currency: currency ?? Currency.USD,
          marketplaceFeatured: marketplaceFeatured as boolean,
          dogepadCollection: dogepadCollection as boolean,
          supply: supply || 0,
        },
      };
    };

    return data.map(mapItem);
  } catch (e: Error | unknown) {
    handleError(e);
    return [];
  }
};

export const fetchDoginalToken = async (
  id: string,
): Promise<DoginalListing> => {
  try {
    const response = await marketplaceApiV2(false).get<{
      doginal: DoginalListing;
    }>(`/doginals/${id}`);

    return response.data?.doginal;
  } catch (e: Error | unknown) {
    handleError(e);
    return {} as DoginalListing;
  }
};
