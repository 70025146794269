import { createContext, useCallback, useEffect, useState } from "react";

import {
  useBalance,
  useDogePrice,
  useLoginPrompt,
  useWalletManagement,
} from "@/contextHooks";
import {
  useCurrentAccount,
  useFetchDrc20,
  useFetchDrc20ListingsForAddress,
  UseFetchDrc20ListingsForAddressReturn,
  useFetchDune,
  useFetchDuneListingsForAddress,
  UseFetchDuneListingsForAddressReturn,
} from "@/hooks";
import {
  BroadcastState,
  BuyItemReturn,
  TxWallet,
  TxWalletProviderProps,
  TxWithFeeEstimation,
} from "./wallet/types";
import { useBuyItem } from "@/context/wallet/buyItem.ts";
import {
  CalculateNetworFeesReturn,
  useCalculateNetworkFees,
} from "@/context/wallet/calculateNetworkFees.ts";
import { SendDrc20Return, useSendDrc20 } from "@/context/wallet/sendDrc20.ts";
import { ListDrc20Return, useListDrc20 } from "@/context/wallet/listDrc20.ts";
import { SendDogeReturn, useSendDoge } from "@/context/wallet/sendDoge.ts";
import {
  DelistDrc20Return,
  useDelistDrc20,
} from "@/context/wallet/delistDrc20.ts";
import { Balance } from "@/context/BalanceProvider.tsx";
import {
  UseSingleDrc20HookReturn,
  UseSingleDunesHookReturn,
} from "@/types/inscription.ts";
import {
  SendCollectiblesReturn,
  useSendCollectibles,
} from "@/context/wallet/sendCollectibles.ts";
import {
  ListCollectiblesReturn,
  useListCollectibles,
} from "@/context/wallet/listCollectibles.ts";
import {
  DelistCollectiblesReturn,
  useDelistCollectibles,
} from "@/context/wallet/delistCollectibles.ts";
import { useShowMissingWallet } from "@/hooks/useShowMissingWallet";
import { ModalMissingWallet } from "@/modals";
import { useUtxoService } from "@/context/wallet/utxoService.ts";

import { SendDuneReturn, useSendDune } from "@/context/wallet/sendDune.ts";
import { ListDuneReturn, useListDune } from "@/context/wallet/listDune.ts";
import {
  DelistDuneReturn,
  useDelistDune,
} from "@/context/wallet/delistDune.ts";

interface TxWalletContextType {
  txWallet?: TxWallet;
  login: (onClose?: () => void) => Promise<boolean>;
  updateTxWallet: (wallet: TxWallet | undefined) => void;
  availableBalance: string;
  dogeBalance: Balance;
  dogePrice: number;
  accountDrc20Listings: UseFetchDrc20ListingsForAddressReturn;
  // @todo: this needs to be integrated here from balanceProvider => @see: buyDoginals / listDoginals
  // accountCollectibleListings: UseFetchDoginalListingsForAddressReturn;
  accountData: UseSingleDrc20HookReturn;
  buyItem: BuyItemReturn;
  sendCollectibles: SendCollectiblesReturn;
  sendDrc20: SendDrc20Return;
  listCollectibles: ListCollectiblesReturn;
  listDrc20: ListDrc20Return;
  sendDoge: SendDogeReturn;
  calculateNetworkFees: CalculateNetworFeesReturn;
  delistCollectibles: DelistCollectiblesReturn;
  delistDrc20: DelistDrc20Return;
  // SDOGGY Dunes enhancement
  accountDuneData: UseSingleDunesHookReturn;
  accountDuneListings: UseFetchDuneListingsForAddressReturn;
  delistDune: DelistDuneReturn;
  listDune: ListDuneReturn;
  sendDune: SendDuneReturn;
}

const TxWalletContext = createContext<TxWalletContextType>({
  txWallet: undefined,
  login: async (onClose?: () => void) => false,
  updateTxWallet: () => undefined,
  availableBalance: "0",
  dogeBalance: {
    utxos: [],
    total_utxos: 0,
    total_shibes: 0,
    dogeBalanceInUsd: 0,
  },
  dogePrice: 0,
  accountDrc20Listings: {
    loading: false,
    fetchListings: async () => undefined,
    error: undefined,
    listings: [],
  },
  // accountCollectibleListings: {
  //   loading: false,
  //   fetchListings: async () => undefined,
  //   error: undefined,
  //   listings: [],
  // },
  accountData: {
    getDrc20Data: async () => undefined,
    getDrc20AccountData: async () => undefined,
    accountDrc20Data: undefined,
    tokenData: undefined,
    loading: false,
  },
  buyItem: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    errors: [],
    result: null,
  },
  sendDrc20: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    txHashes: [],
  },
  sendCollectibles: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    txHashes: [],
  },
  listDrc20: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    txHashes: [],
  },
  listCollectibles: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
  },
  sendDoge: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    calculateMaxSendableDoge: async () => undefined,
    reset: () => undefined,
    error: null,
    txHash: null,
  },
  calculateNetworkFees: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    totalFeeInDoge: 0,
    totalFeeInSats: 0,
  },
  delistDrc20: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    preparationErrors: null,
    data: null,
  },
  delistCollectibles: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    preparationErrors: null,
    data: null,
  },
  // SDOGGY Dunes enhancement
  accountDuneData: {
    getDuneData: async () => undefined,
    getDuneAccountData: async () => undefined,
    accountDunesData: undefined,
    tokenData: undefined,
    loading: false,
  },
  accountDuneListings: {
    loading: false,
    fetchListings: async () => undefined,
    error: undefined,
    listings: [],
  },
  sendDune: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    txHashes: [],
  },
  listDune: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    txHashes: [],
  },
  delistDune: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    execute: async () => undefined,
    reset: () => undefined,
    error: null,
    preparationErrors: null,
    data: null,
  },
});

const TxWalletProvider = ({ children }: TxWalletProviderProps) => {
  const WalletManagementContext = useWalletManagement();
  const { dogePrice } = useDogePrice();
  const {
    safeUtxos,
    refetchDogeBalance,
    dogeBalance,
    availableBalance,
    isDogeBalanceLoading: isLoadingDogeBalance,
    isDrc20Loading: isLoadingDrc20,
    isCollectiblesBalanceLoading: isLoadingCollectiblesBalance,
    isDunesLoading,
    refetchDrc20s,
    refetchCollectibles,
    refetchDunes,
  } = useBalance();
  const { showAccountPrivateKey, pw } = WalletManagementContext || {};
  const { address, publicKey } = useCurrentAccount();
  const [txWallet, setTxWallet] = useState<TxWallet | undefined>();

  const accountData = useFetchDrc20();
  // these are the drc20Offers from MongoDB (including pending offers)
  const accountDrc20Listings = useFetchDrc20ListingsForAddress({ address });
  const buyItem = useBuyItem({ txWallet });
  const sendDrc20 = useSendDrc20({ txWallet, address });
  const listDrc20 = useListDrc20({ txWallet, address });
  const sendDoge = useSendDoge({ txWallet, dogePrice, dogeBalance });
  const delistDrc20 = useDelistDrc20({
    txWallet,
    accountData: accountData.accountDrc20Data,
  });
  const delistCollectibles = useDelistCollectibles({ txWallet });
  const sendCollectibles = useSendCollectibles({ txWallet, address });
  const listCollectibles = useListCollectibles({ txWallet, address });

  const accountDunesData = useFetchDune();
  const accountDuneListings = useFetchDuneListingsForAddress({ address });
  const sendDune = useSendDune({ txWallet, address });
  const listDune = useListDune({ txWallet, address });
  const delistDune = useDelistDune({
    txWallet,
    accountData: accountDunesData.accountDunesData,
  });

  const calculateNetworkFees = useCalculateNetworkFees({ txWallet, address });
  const { onShowMissingWallet } = useShowMissingWallet({
    address: address || "",
  });
  const [isMissingWallet, setIsMissingWallet] = useState<boolean>(false);
  const [missingWalletOnClose, setMissingWalletOnClose] = useState<
    (() => void) | null
  >(null);
  const utxoService = useUtxoService(address);

  // Sync UTXOs from safeUtxos
  const syncUtxos = useCallback(
    async (forceSync: boolean = false) => {
      const storeInitialized = utxoService.initialized;

      // Check for required data
      if (!address || !safeUtxos || !storeInitialized) {
        return;
      }

      if (forceSync) {
        console.log("TxWalletProvider - syncUtxos - forceSync");
        utxoService.clearUtxos();
      }

      // Sync UTXOs
      utxoService.syncUtxos({ safeUtxos, address });
    },
    [address, safeUtxos, utxoService.initialized],
  );

  // Assemble wallet after UTXO store updates
  const assembleWallet = useCallback(async () => {
    const storeInitialized = utxoService.initialized;

    if (!address || !pw || !publicKey || !storeInitialized) {
      return;
    }

    // Retrieve private key for the wallet assembly
    const privKey = await showAccountPrivateKey?.(address, pw);
    if (!privKey) {
      setTxWallet(undefined);
      return;
    }

    const externalAddress = address;
    // Assemble a new wallet with the synced UTXOs
    const newWallet: TxWallet = {
      address,
      get utxos() {
        console.log(
          "TxWalletProvider - assembleWallet - get utxos - address",
          address,
        );
        const utxos = utxoService.getStore(address)?.utxos || [];
        console.log(
          "TxWalletProvider - assembleWallet - get utxos - utxos",
          utxos,
        );
        return utxos;
      },
      pubKey: publicKey,
      privKey: privKey,
      updateUtxos: ({ address, tx, outputsToSkip }) => {
        if (!address) {
          address = externalAddress;
        }

        utxoService.updateUtxos({
          address,
          tx,
          outputsToSkip: outputsToSkip || [],
        });
      },
      syncUtxos: async (forceSync: boolean = false) => {
        console.log("TxWalletProvider - assembleWallet - syncUtxos");
        await syncUtxos(forceSync);
      },
    };

    setTxWallet(newWallet);
    console.log("New wallet assembled:", newWallet);
  }, [utxoService.initialized, address, pw, publicKey, showAccountPrivateKey]);

  // Effect 1: Sync UTXOs whenever safeUtxos change
  useEffect(() => {
    syncUtxos().then(() => {
      console.log("UTXOs synced after safeUtxos change.");
    });
  }, [syncUtxos]);

  // Effect 2: Assemble wallet when UTXO store is updated
  useEffect(() => {
    assembleWallet().then(() => {
      console.log("Wallet assembled after UTXO store update.");
    });
  }, [assembleWallet]);

  /**
   * reset states after success / error
   */
  const refetchBalances = useCallback(() => {
    // wait for the transaction to be in mempool and picked by wonky
    setTimeout(() => {
      Promise.all([
        refetchDogeBalance(address),
        refetchDrc20s(),
        refetchCollectibles(),
        refetchDunes(),
      ]);
    }, 5000);
  }, [
    address,
    refetchCollectibles,
    refetchDogeBalance,
    refetchDrc20s,
    refetchDunes,
  ]);

  const { openLoginPrompt } = useLoginPrompt();
  const login = useCallback(
    async (onClose?: () => void): Promise<boolean> => {
      let isLoggedIn = false;

      if (txWallet) {
        isLoggedIn = true;
      } else {
        const showMissingWallet = await onShowMissingWallet();
        if (!showMissingWallet) {
          // if there is no txWallet, it means -> not logged in
          // we prompt for login credentials
          const entered = await openLoginPrompt(true);

          // Type guard to handle both PIN and Password
          const enteredCredential =
            entered &&
            typeof entered === "object" &&
            "pin" in entered &&
            Array.isArray(entered.pin)
              ? entered.pin.join("")
              : entered;

          // we double check if login credentials were correct
          const privKey = await showAccountPrivateKey?.(
            address!,
            enteredCredential as string,
          );
          isLoggedIn = !!privKey;
          refetchBalances();
        } else {
          if (onClose) {
            setMissingWalletOnClose(() => onClose);
          }

          setIsMissingWallet(showMissingWallet);
        }
      }
      return isLoggedIn;
    },
    [
      address,
      onShowMissingWallet,
      openLoginPrompt,
      refetchBalances,
      showAccountPrivateKey,
      txWallet,
    ],
  );

  // start wait for confirmation after successful sendingDoge
  useEffect(() => {
    if (!isLoadingDogeBalance && sendDoge.isSuccess && address) {
      sendDoge.reset();
      refetchBalances();
    }
  }, [
    isLoadingDogeBalance,
    address,
    refetchDogeBalance,
    sendDoge.isSuccess,
    sendDoge,
    refetchBalances,
  ]);

  useEffect(() => {
    if (!isLoadingDrc20 && sendDoge.isError) {
      console.log(
        "TxWalletProvider - useEffect - sendDoge.isError",
        sendDoge.isError,
      );
      sendDoge.reset();
      refetchBalances();
    }
  }, [isLoadingDrc20, refetchBalances, sendDoge, sendDoge.isError]);

  useEffect(() => {
    if (!isLoadingDrc20 && sendDrc20.isSuccess && address) {
      console.log(
        "TxWalletProvider - useEffect - sendDrc20.isSuccess",
        sendDrc20.isSuccess,
      );
      console.log("TxWalletProvider - useEffect - address", address);
      refetchBalances();
    }
  }, [
    isLoadingDrc20,
    address,
    sendDrc20.isSuccess,
    refetchDrc20s,
    refetchDogeBalance,
    refetchBalances,
  ]);

  useEffect(() => {
    if (!isLoadingDrc20 && sendDrc20.isError) {
      console.log(
        "TxWalletProvider - useEffect - sendDrc20.isError",
        sendDrc20.isError,
      );
      sendDrc20.reset();
      refetchBalances();
    }
  }, [isLoadingDrc20, refetchBalances, sendDrc20, sendDrc20.isError]);

  // start wait for confirmation after successful listing a drc20 item
  useEffect(() => {
    if (!isLoadingDogeBalance && listDrc20.isSuccess && address) {
      listDrc20.reset();
      refetchBalances();
    }
  }, [
    isLoadingDogeBalance,
    address,
    listDrc20.isSuccess,
    listDrc20,
    refetchBalances,
  ]);

  useEffect(() => {
    if (!isLoadingDrc20 && listDrc20.isError) {
      console.log(
        "TxWalletProvider - useEffect - listDrc20.isError",
        listDrc20.isError,
      );
      syncUtxos(true).then(() => {
        listDrc20.reset();
        refetchBalances();
      });
    }
  }, [isLoadingDrc20, listDrc20, refetchBalances, sendDoge.isError, syncUtxos]);

  // start wait for confirmation after successful buying an item
  useEffect(() => {
    if (!isLoadingDogeBalance && buyItem.isSuccess && address) {
      refetchBalances();
    }
  }, [isLoadingDogeBalance, address, buyItem.isSuccess, refetchBalances]);

  useEffect(() => {
    if (!isLoadingDrc20 && buyItem.isError) {
      console.log(
        "TxWalletProvider - useEffect - buyItem.isError",
        buyItem.isError,
      );
      syncUtxos(true).then(() => {
        refetchBalances();
      });
    }
  }, [isLoadingDrc20, buyItem.isError, refetchBalances, syncUtxos]);

  // start wait for confirmation after successful sendingDoge
  useEffect(() => {
    if (
      !isLoadingDogeBalance &&
      !isLoadingDrc20 &&
      delistDrc20.isSuccess &&
      address
    ) {
      delistDrc20.reset();
      refetchBalances();
    }
  }, [
    isLoadingDogeBalance,
    address,
    delistDrc20,
    refetchBalances,
    isLoadingDrc20,
  ]);

  useEffect(() => {
    if (
      !isLoadingDogeBalance &&
      !isLoadingDrc20 &&
      (delistDrc20.isError || delistDrc20.preparationErrors)
    ) {
      console.log(
        "TxWalletProvider - useEffect - delistDrc20.isError",
        delistDrc20.isError,
      );
      syncUtxos(true).then(() => {
        delistDrc20.reset();
        refetchBalances();
      });
    }
  }, [
    isLoadingDrc20,
    delistDrc20,
    refetchBalances,
    isLoadingDogeBalance,
    syncUtxos,
  ]);

  useEffect(() => {
    if (
      !isLoadingDogeBalance &&
      !isLoadingCollectiblesBalance &&
      delistCollectibles.isSuccess
    ) {
      console.log(
        "TxWalletProvider - useEffect - delistCollectibles.isSuccess",
        delistCollectibles.isSuccess,
      );

      const delistData = delistCollectibles.data;
      if (delistData && delistData.errorsBroadcasting.length > 0) {
        console.log(
          "TxWalletProvider - useEffect - delistCollectibles.isSuccess - errorsBroadcasting",
          delistData.errorsBroadcasting,
        );

        syncUtxos(true).then(() => {
          delistCollectibles.reset();
          refetchBalances();
        });
      } else {
        delistCollectibles.reset();
        refetchBalances();
      }
    }
  }, [
    isLoadingDogeBalance,
    refetchBalances,
    delistCollectibles,
    isLoadingCollectiblesBalance,
    syncUtxos,
  ]);

  useEffect(() => {
    if (
      !isLoadingDogeBalance &&
      !isLoadingCollectiblesBalance &&
      (delistCollectibles.isError || delistCollectibles.error)
    ) {
      console.log(
        "TxWalletProvider - useEffect - delistCollectibles.isError",
        delistCollectibles.isError,
      );
      syncUtxos(true).then(() => {
        delistCollectibles.reset();
        refetchBalances();
      });
    }
  }, [
    refetchBalances,
    isLoadingDogeBalance,
    isLoadingCollectiblesBalance,
    delistCollectibles,
    syncUtxos,
  ]);

  // SDOGGY enhancement start
  useEffect(() => {
    if (!isDunesLoading && sendDune.isSuccess && address) {
      console.log(
        "TxWalletProvider - useEffect - sendDune.isSuccess",
        sendDune.isSuccess,
      );
      console.log("TxWalletProvider - useEffect - address", address);
      console.log("TxWalletProvider - useEffect - sendDune.reset");
      syncUtxos(true).then(() => {
        sendDune.reset();
        refetchBalances();
      });
    }
  }, [
    isDunesLoading,
    address,
    sendDune.isSuccess,
    refetchDunes,
    refetchDogeBalance,
    sendDune,
    refetchBalances,
  ]);

  useEffect(() => {
    if (!isDunesLoading && sendDune.isError) {
      console.log(
        "TxWalletProvider - useEffect - sendDune.isError",
        sendDune.isError,
      );
      syncUtxos(true).then(() => {
        sendDune.reset();
        refetchBalances();
      });
    }
  }, [isDunesLoading, refetchBalances, sendDune, sendDune.isError]);

  // start wait for confirmation after successful sendingDoge
  useEffect(() => {
    if (!isLoadingDogeBalance && listDune.isSuccess && address) {
      syncUtxos(true).then(() => {
        listDune.reset();
        refetchBalances();
      });
    }
  }, [
    isLoadingDogeBalance,
    address,
    refetchDogeBalance,
    listDune.isSuccess,
    listDune,
    refetchDunes,
    refetchBalances,
  ]);

  useEffect(() => {
    if (!isDunesLoading && listDune.isError) {
      console.log(
        "TxWalletProvider - useEffect - listDune.isError",
        listDune.isError,
      );
      syncUtxos(true).then(() => {
        listDune.reset();
        refetchBalances();
      });
    }
  }, [isDunesLoading, listDune, refetchBalances, sendDoge.isError]);

  // start wait for confirmation after successful sendingDoge
  useEffect(() => {
    if (!isLoadingDogeBalance && delistDune.isSuccess && address) {
      syncUtxos(true).then(() => {
        delistDune.reset();
        refetchBalances();
      });
    }
  }, [isLoadingDogeBalance, address, delistDune, refetchBalances]);

  useEffect(() => {
    if (
      !isDunesLoading &&
      (delistDune.isError || delistDune.preparationErrors)
    ) {
      console.log(
        "TxWalletProvider - useEffect - delistDune.isError",
        delistDune.isError,
      );
      syncUtxos(true).then(() => {
        delistDune.reset();
        refetchBalances();
      });
    }
  }, [isDunesLoading, delistDune, refetchBalances]);
  // SDOGGY enhancement end

  useEffect(() => {
    if (
      !calculateNetworkFees.isLoading &&
      (calculateNetworkFees.isError || calculateNetworkFees.isSuccess)
    ) {
      console.log(
        "TxWalletProvider - useEffect - calculateNetworkFees.isError",
        calculateNetworkFees.isError,
      );
      console.log(
        "TxWalletProvider - useEffect - calculateNetworkFees.isSuccess",
        calculateNetworkFees.isSuccess,
      );
      calculateNetworkFees.reset();
    }
  }, [calculateNetworkFees]);

  return (
    <TxWalletContext.Provider
      value={{
        txWallet,
        availableBalance,
        dogeBalance,
        dogePrice,
        updateTxWallet: setTxWallet,
        login,
        accountDrc20Listings,
        // accountCollectibleListings,
        accountData,
        buyItem,
        sendDrc20,
        sendCollectibles,
        listDrc20,
        listCollectibles,
        sendDoge,
        delistDrc20,
        delistCollectibles,
        calculateNetworkFees,
        // SDOGGY Dunes enhancement
        accountDuneData: accountDunesData,
        accountDuneListings,
        sendDune,
        listDune,
        delistDune,
      }}
    >
      {children}
      <ModalMissingWallet
        withBottomNav
        isVisible={isMissingWallet}
        onCancel={() => {
          if (missingWalletOnClose) {
            missingWalletOnClose();
          }
          setIsMissingWallet(false);
        }}
        onClose={() => {
          if (missingWalletOnClose) {
            missingWalletOnClose();
          }
          setIsMissingWallet(false);
        }}
      />
    </TxWalletContext.Provider>
  );
};

export { TxWalletProvider, TxWalletContext, BroadcastState };
export type { TxWallet, TxWithFeeEstimation };
