import { cn } from "@/lib/utils";
import { getColorForChange } from "@/utility";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import { Button } from "../ui/button";

interface ActivityCardNFTProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  title: string;
  description?: string;
  valuePrimary: string;
  valueSecondary?: string;
  valueSecondarySuffix?: string;
  valueSecondaryHideOnMobile?: boolean;
  valueSecondaryIsChange?: boolean;
  showInfoOnHover?: boolean;
}

export const ActivityCardNFT: React.FC<ActivityCardNFTProps> = ({
  title,
  description,
  image,
  valuePrimary,
  valueSecondary,
  valueSecondarySuffix,
  valueSecondaryHideOnMobile = false,
  valueSecondaryIsChange = true,
  showInfoOnHover = true,

  style,
  className,

  onClick,
}) => {
  const colorPercentage =
    valueSecondary !== undefined && getColorForChange(valueSecondary);
  const showSecondaryValue =
    valueSecondary !== "0%" &&
    valueSecondary !== "0" &&
    valueSecondary !== undefined;
  return (
    <AnimatedClickable
      onClick={onClick}
      className={cn(
        "group relative flex aspect-square w-full flex-shrink-0 cursor-pointer flex-col space-y-2 overflow-hidden rounded-lg bg-background-secondary p-2 md:max-w-40 lg:max-w-64",
        className,
      )}
      style={style}
      whileHover={{ scale: showInfoOnHover ? 1 : 1.02 }}
    >
      <ImageWithFallback
        image={image}
        className={cn(
          "absolute bottom-0 left-0 right-0 top-0 z-0 h-full w-full rounded-lg border-0.5 border-border-primary bg-background-secondary",
          showInfoOnHover &&
            "transition-scale duration-500 md:group-hover:scale-125",
        )}
      />

      <div
        className={cn(
          "absolute bottom-0 left-0 right-0 z-0 h-2/3 rounded-lg bg-gradient-to-t from-background-primary/80 to-transparent transition-all duration-200",
          showInfoOnHover && "md:group-hover:h-full",
        )}
      />

      <div className="group z-10 flex flex-1 flex-col justify-end">
        <div className="overflow-hidden">
          <span className="truncate text-sm font-bold text-text-primary md:text-md lg:text-lg">
            {title}
          </span>
          <div className="flex flex-row items-baseline justify-between space-x-1">
            <span
              className="text-xs font-semibold text-text-primary md:text-md lg:text-lg"
              dangerouslySetInnerHTML={{ __html: valuePrimary }}
            />
            {showSecondaryValue && valueSecondary && (
              <div
                className={cn(
                  "items-center justify-start space-x-2",
                  valueSecondaryHideOnMobile && "hidden md:flex",
                )}
              >
                <span
                  className={cn(
                    "text-right text-xs font-semibold text-text-tertiary md:text-sm lg:text-md",
                    valueSecondaryIsChange && colorPercentage,
                  )}
                  dangerouslySetInnerHTML={{ __html: valueSecondary }}
                />

                {valueSecondarySuffix && (
                  <span className="rounded-full bg-background-tertiary/50 px-2 py-0.5 text-xs font-bold text-text-tertiary">
                    {valueSecondarySuffix}
                  </span>
                )}
              </div>
            )}
          </div>
          {!!showInfoOnHover && (
            <div className="transition-max-h max-h-0 duration-500 md:group-hover:max-h-[150px]">
              <div className="mb-4 flex-col gap-2">
                {description && (
                  <span className="line-clamp-3 text-xs text-text-primary md:text-sm">
                    {description}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>

        {!!showInfoOnHover && (
          <div className="transition-max-h pointer-events-none max-h-0 opacity-0 duration-500 md:group-hover:pointer-events-auto md:group-hover:max-h-[150px] md:group-hover:opacity-100">
            <div className="flex flex-col gap-1">
              <Button variant="inverse" className="border-none">
                Trade
              </Button>
            </div>
          </div>
        )}
      </div>
    </AnimatedClickable>
  );
};
