import { motion } from "framer-motion";

import { Button, ImageWithFallback, Spinner } from "@/components";
import { ALPHA_DISCOUNT, LISTING_MATCHING_FEE } from "@/constants";
import { useInfoModal } from "@/hooks";
import { AnimatedContent, ExpandableFeeList, LegalFooter } from "@/modals/base";
import { ModalInfo } from "@/modals/modalInfo";
import { InputCurrency } from "@/components/inputCurrency";
import { Currency } from "@/types";

export interface Listing {
  image: string;
  itemName: string;
  collectionName: string;
  onRemove: () => void;

  price: string;
  floorPrice: string;
  floorPriceDeltaPercentage: string;
  onPriceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPriceFloor: () => void;
}

interface Fees {
  trading: string;
  tradingDiscountAlpha: string;
  service: string;
  total: string;
}

interface Proceeds {
  potentialProceedsInUSD: string;
  potentialProceedsInDoge: string;
}

interface ListViewProps {
  fees: Fees;
  loading: boolean;
  proceeds: Proceeds;
  listings: Listing[];
  handleList: () => void;
  onFloorAll: () => void;
  isBelowThreshold: boolean;
  hasItemWithoutPrice: boolean;
}

export const ListView: React.FC<ListViewProps> = ({
  fees,
  proceeds,
  handleList,
  onFloorAll,
  loading,
  listings = [],
  isBelowThreshold,
  hasItemWithoutPrice,
}) => {
  const { potentialProceedsInDoge } = proceeds;
  const { info, infoVisible, FeeType, handleOpenInfoFee, handleCloseInfo } =
    useInfoModal();

  return (
    <AnimatedContent id="list" className="p-0">
      <div className="relative flex max-h-screen flex-1 flex-col space-y-4 overflow-y-scroll px-4 pb-48">
        <div className="flex flex-col space-y-2 pt-2">
          {listings.map((item, index) => (
            <ListingCard
              key={index}
              image={item.image}
              itemName={item.itemName}
              collectionName={item.collectionName}
              onRemove={item.onRemove}
              onPriceChange={item.onPriceChange}
              onPriceFloor={item.onPriceFloor}
              price={item.price}
              floorPrice={item.floorPrice}
              floorPriceDeltaPercentage={item.floorPriceDeltaPercentage}
            />
          ))}
        </div>

        {/** Action Bar */}
        <motion.div className="fixed bottom-0 left-0 flex w-full flex-col space-y-2 border-t border-border-primary bg-background-primary/80 p-4 drop-shadow-xl backdrop-blur-xl pb-safe-offset-2 md:relative">
          {/** List */}
          <motion.div
            layout="position"
            transition={{ duration: 0.1 }}
            className="flex flex-col space-y-1 px-1"
          >
            <div className="flex h-8 flex-row items-center justify-between">
              <span className="text-sm text-text-primary">
                Potential Proceeds
              </span>
              <span
                className="text-right text-sm font-semibold"
                dangerouslySetInnerHTML={{
                  __html: potentialProceedsInDoge,
                }}
              />
            </div>

            <ExpandableFeeList
              fees={[
                {
                  label: `Matching Fee (${LISTING_MATCHING_FEE}%)`,
                  value: fees.trading,
                  onClick: () => handleOpenInfoFee(FeeType.MATCHING),
                },
                {
                  label: `Alpha Phase Discount (${ALPHA_DISCOUNT}%)`,
                  value: `- ${fees.tradingDiscountAlpha}`,
                  onClick: () => handleOpenInfoFee(FeeType.MATCHING),
                },
                {
                  label: "Service Fee",
                  value: "100% Discount",
                  onClick: () => handleOpenInfoFee(FeeType.SERVICE),
                },
              ]}
              totalFees={fees.total}
            />
          </motion.div>

          {/** Actions */}
          {!hasItemWithoutPrice && isBelowThreshold && (
            <span className="text-xs text-red-500">
              Listings must exceed the minimum 2 DOGE threshold.
            </span>
          )}
          <div className="flex flex-1 flex-row space-x-2">
            <Button
              size="large"
              variant="inverse"
              onClick={handleList}
              className="flex-1"
              disabled={
                isBelowThreshold || hasItemWithoutPrice || listings.length === 0
              }
            >
              {loading ? <Spinner size={24} /> : "List"}
            </Button>
            <Button
              size="large"
              variant="default"
              onClick={onFloorAll}
              disabled={listings.length === 0}
            >
              Floor It 🌚
            </Button>
          </div>

          <LegalFooter label="List" />
        </motion.div>
      </div>
      <ModalInfo
        info={info}
        isVisible={infoVisible}
        onClose={handleCloseInfo}
      />
    </AnimatedContent>
  );
};

interface ListingCardProps {
  image: string;
  itemName: string;
  collectionName: string;
  onRemove: () => void;

  price: string;
  floorPrice: string;
  floorPriceDeltaPercentage: string;
  onPriceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPriceFloor: () => void;
}

const ListingCard: React.FC<ListingCardProps> = ({
  image,
  itemName,
  collectionName,
  onRemove,
  price,
  floorPrice,
  floorPriceDeltaPercentage,
  onPriceChange,
  onPriceFloor,
}: ListingCardProps) => {
  return (
    <div className="relative flex flex-col overflow-visible rounded-lg bg-background-secondary p-2">
      <div className="flex flex-row space-x-4">
        <ImageWithFallback image={image} className="h-20 w-20 rounded-md" />
        <div className="flex flex-1 flex-col justify-center space-y-2">
          <span className="text-xs font-semibold text-text-primary">
            {itemName}
          </span>
          <span className="text-xs text-text-tertiary">{collectionName}</span>
          <span
            className="text-xs text-text-tertiary"
            dangerouslySetInnerHTML={{
              __html: `Floor: ${floorPrice} • Difference: ${floorPriceDeltaPercentage}`,
            }}
          />
        </div>
      </div>

      <div className="mt-2 flex flex-col space-y-1">
        <div className="relative flex flex-row items-center space-x-1 rounded-md bg-background-tertiary pr-2">
          <InputCurrency
            className="h-12 bg-background-tertiary text-sm font-medium text-text-primary"
            symbolDirection="prefix"
            symbol={Currency.DOGE}
            placeholder="0"
            onChange={onPriceChange}
            value={price}
          />
          <Button
            variant="ghost"
            shape="circle"
            onClick={onPriceFloor}
            className="h-8 bg-background-secondary"
          >
            <span className="text-xs font-bold">Floor</span>
          </Button>
        </div>
      </div>
      <button
        onClick={onRemove}
        className="absolute -right-1 -top-1 flex h-6 w-6 items-center justify-center rounded-full border-0.5 border-background-primary bg-background-secondary"
      >
        <span className="material-symbols-rounded text-md text-text-primary">
          close
        </span>
      </button>
    </div>
  );
};
