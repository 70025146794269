import { AnimatePresence, motion } from "framer-motion";

import { cn } from "@/lib/utils";
import { useToggle } from "@uidotdev/usehooks";

interface FeeListItemProps {
  label: string | React.ReactNode;
  value: string;
  onClick: () => void;
}

export const FeeListItem: React.FC<FeeListItemProps> = ({
  label,
  value,
  onClick,
}: FeeListItemProps) => {
  return (
    <div
      className="flex h-10 flex-row items-center justify-between"
      onClick={onClick}
    >
      <div className="flex flex-row items-center space-x-1">
        <span className="text-sm text-text-primary">{label}</span>
        <span className="material-symbols-rounded text-xs text-text-tertiary">
          info
        </span>
      </div>
      <span
        className="text-right text-sm text-text-primary"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </div>
  );
};

interface ExpandableFeeListProps {
  fees: FeeListItemProps[];
  totalFees: string;
}

export const ExpandableFeeList: React.FC<ExpandableFeeListProps> = ({
  fees,
  totalFees,
}) => {
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <motion.div
      layout="position"
      transition={{ duration: 0.1 }}
      className="flex flex-col"
    >
      <div className="flex h-8 flex-row items-center justify-between">
        <div
          className="flex flex-row items-center space-x-1"
          onClick={() => toggleExpanded()}
        >
          <span className="text-sm text-text-primary">Total Fees</span>
          <span
            className={cn(
              "material-symbols-rounded text-sm text-text-primary",
              { "rotate-180": expanded },
            )}
          >
            expand_more
          </span>
        </div>
        <span
          className="text-right text-sm font-semibold text-text-primary"
          dangerouslySetInnerHTML={{ __html: totalFees }}
        />
      </div>

      {expanded && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.1, duration: 0.1 }}
            className="my-2 rounded-lg border border-border-primary bg-background-secondary/50 px-4 py-2"
          >
            {fees.map((fee, index) => (
              <FeeListItem
                key={index}
                label={fee.label}
                value={fee.value}
                onClick={fee.onClick}
              />
            ))}
          </motion.div>
        </AnimatePresence>
      )}
    </motion.div>
  );
};
