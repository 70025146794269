import { ReactNode, createContext, useEffect, useMemo, useRef } from "react";

import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { EMPTY_BALANCE } from "@/context/balance";
import {
  Drc20OverviewDetail,
  DunesOverviewDetail,
  useBalanceOverviewOfAddress,
  useCurrentAccount,
  useDogeBalanceOfAddress,
  useFetchDoginalInfo,
  useFetchDrc20s,
  useFetchDunes,
} from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import {
  BalanceOverviewWithCalculatedPrices,
  Currency,
  DogeUtxo,
  DoginalInfo,
  Inscription,
} from "@/types";

import { defaultEstimatedTotalValue } from "./helpers/fetchBalanceOverview";
import {
  PendingCollectiblesBalance,
  useFetchDoginals,
} from "@/hooks/datafetching/useFetchDoginals.ts";

type BalanceResponse = {
  utxos: DogeUtxo[];
  total_utxos: number;
  total_shibes: number;
};

type Balance = BalanceResponse & { dogeBalanceInUsd: number };

interface BalanceContextType {
  availableBalance: string;
  dogeBalance: Balance;
  refetchDogeBalance: (address?: string) => Promise<void>;
  safeUtxos: DogeUtxo[];
  balanceInDOGE: string;
  balanceInUSD: string;
  balanceInDOGEForHeader: string;
  balanceInUSDForHeader: string;
  isDogeBalanceLoading: boolean;
  drc20s: Drc20OverviewDetail[];
  collectibles: DoginalInfo[];
  pendingSendingCollectibles: PendingCollectiblesBalance[];
  pendingReceivingCollectibles: PendingCollectiblesBalance[];
  refetchCollectibles: () => Promise<void>;
  isCollectiblesLoading: boolean;
  collectiblesBalance: Inscription[];
  refetchCollectiblesBalance: () => Promise<void>;
  isCollectiblesBalanceLoading: boolean;
  refetchDrc20s: () => Promise<void>;
  isDrc20Loading: boolean;
  balanceOverview: BalanceOverviewWithCalculatedPrices;
  refetchBalanceOverview: () => Promise<void>;
  isLoadingBalanceOverviewOfAddress: boolean;
  // SDOGGS enhancements start
  dunes: DunesOverviewDetail[];
  refetchDunes: () => Promise<void>;
  isDunesLoading: boolean;
  // SDOGGS enhancements end
}

type BalanceProviderProps = {
  children: ReactNode;
  address?: string;
};

const BalanceContext = createContext<BalanceContextType>({
  availableBalance: "0",
  dogeBalance: EMPTY_BALANCE,
  refetchDogeBalance: async () => {},
  safeUtxos: [],
  balanceInDOGE: `${Currency.DOGE}0`,
  balanceInUSD: `${Currency.USD}0`,
  isDogeBalanceLoading: false,
  drc20s: [],
  refetchDrc20s: async () => {},
  isDrc20Loading: false,
  collectibles: [],
  pendingSendingCollectibles: [],
  pendingReceivingCollectibles: [],
  refetchCollectibles: async () => {},
  isCollectiblesLoading: false,
  collectiblesBalance: [],
  refetchCollectiblesBalance: async () => {},
  isCollectiblesBalanceLoading: false,
  balanceOverview: {
    ...defaultEstimatedTotalValue,
    totalInDOGE: 0,
    totalInUSD: 0,
    doginalsValue: 0,
    doginalsValueInDoge: 0,
    doginalsValueInUSD: 0,
    drc20sValue: 0,
    drc20sValueInDoge: 0,
    drc20sValueInUSD: 0,
    dunesValue: 0,
    dunesValueInDoge: 0,
    dunesValueInUSD: 0,
    safeBalance: 0,
    safeBalanceInDoge: 0,
    safeBalanceInUSD: 0,
  },
  isLoadingBalanceOverviewOfAddress: false,
  refetchBalanceOverview: async () => {},
  balanceInDOGEForHeader: `${Currency.DOGE}0`,
  balanceInUSDForHeader: `${Currency.USD}0`,
  dunes: [],
  refetchDunes: async () => {},
  isDunesLoading: false,
});

const BalanceProvider = ({
  children,
  address: searchAddress,
}: BalanceProviderProps) => {
  const { address: currentAddress } = useCurrentAccount();
  const address = useMemo(
    () => searchAddress ?? currentAddress,
    [searchAddress, currentAddress],
  );
  const {
    drc20s,
    loading: isDrc20Loading,
    getDrc20s,
  } = useFetchDrc20s(address);

  const { dunes, loading: isDunesLoading, getDunes } = useFetchDunes(address);

  const {
    loading: isCollectiblesLoading,
    collectibleInfo: collectibles,
    getCollectibles: refetchCollectibles,
  } = useFetchDoginalInfo(address);

  const {
    loading: isCollectiblesBalanceLoading,
    collectibles: collectiblesBalance,
    pendingReceivingCollectibles,
    pendingSendingCollectibles,
    getCollectibles: refetchCollectiblesBalance,
  } = useFetchDoginals(address);

  const {
    balanceInDOGE,
    balanceInUSD,
    dogeBalance,
    safeUtxos,
    getDogeBalanceOfAddress: refetchDogeBalance,
    isLoading: isDogeBalanceLoading,
  } = useDogeBalanceOfAddress(address);

  const {
    balanceOverview,
    isLoading: isLoadingBalanceOverviewOfAddress,
    fetchData: refetchBalanceOverview,
  } = useBalanceOverviewOfAddress(address);

  // ensure balance is fetched on mount (when address is there) and then every minute
  const intervalRef = useRef<null | NodeJS.Timeout>(null);
  useEffect(() => {
    if (
      !address ||
      isDogeBalanceLoading ||
      isLoadingBalanceOverviewOfAddress ||
      isDrc20Loading ||
      isCollectiblesLoading
    )
      return;

    const fetchAllBalances = async () => {
      console.log(
        "BalanceProvider - fetchAllBalances - via interval for address",
        address,
      );

      await refetchDogeBalance();
      console.log(
        "BalanceProvider - fetchAllBalances - via interval for address after refetchDogeBalance",
      );
      await refetchBalanceOverview();
      console.log(
        "BalanceProvider - fetchAllBalances - via interval for address after refetchBalanceOverview",
      );
      await getDrc20s();
      console.log(
        "BalanceProvider - fetchAllBalances - via interval for address after getDrc20s",
      );
      await getDunes();
      console.log(
        "BalanceProvider - fetchAllBalances - via interval for address after getDunes",
      );
      await refetchCollectibles();
      console.log(
        "BalanceProvider - fetchAllBalances - via interval for address after refetchCollectibles",
      );
    };

    // Clear the existing interval if it exists
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Set a new interval
    intervalRef.current = setInterval(fetchAllBalances, 60000);

    // Cleanup on unmount
    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
  }, [
    address,
    isDogeBalanceLoading,
    isLoadingBalanceOverviewOfAddress,
    isDrc20Loading,
    refetchBalanceOverview,
    refetchDogeBalance,
    getDrc20s,
    getDunes,
    isCollectiblesLoading,
    refetchCollectibles,
  ]);

  const { balanceInUSDForHeader, balanceInDOGEForHeader } = useMemo(() => {
    let formattedUSD = balanceInUSD;
    let formattedDOGE = balanceInDOGE;
    // special rule for header to save space in case of large numbers
    if (dogeBalance.total_shibes / ONE_DOGE_IN_SHIBES > 1000) {
      formattedUSD = `${Currency.USD}${formatNumber({ value: dogeBalance.dogeBalanceInUsd, type: NumberFormatType.Large_Number, decimalPlaces: 2 })}`;
      formattedDOGE = `${Currency.DOGE}${formatNumber({ value: dogeBalance.total_shibes / ONE_DOGE_IN_SHIBES, type: NumberFormatType.Large_Number, decimalPlaces: 2 })}`;
    }

    return {
      balanceInUSDForHeader: formattedUSD,
      balanceInDOGEForHeader: formattedDOGE,
    };
  }, [
    dogeBalance.dogeBalanceInUsd,
    dogeBalance.total_shibes,
    balanceInUSD,
    balanceInDOGE,
  ]);

  const availableBalance = useMemo(() => {
    return formatNumber({
      value: dogeBalance.total_shibes / ONE_DOGE_IN_SHIBES,
      type: NumberFormatType.Price,
      decimalPlaces: 2,
    });
  }, [dogeBalance]);

  return (
    <BalanceContext.Provider
      value={{
        availableBalance,
        dogeBalance,
        refetchDogeBalance,
        safeUtxos,
        balanceInDOGE,
        balanceInUSD,
        balanceInDOGEForHeader,
        balanceInUSDForHeader,
        isDogeBalanceLoading,
        // SDOGGS enhancements start
        dunes,
        refetchDunes: getDunes,
        isDunesLoading,
        // SDOGGS enhancements end
        drc20s,
        refetchDrc20s: getDrc20s,
        isDrc20Loading,
        collectibles,
        pendingSendingCollectibles,
        pendingReceivingCollectibles,
        refetchCollectibles,
        isCollectiblesLoading,
        collectiblesBalance,
        refetchCollectiblesBalance,
        isCollectiblesBalanceLoading,
        balanceOverview: balanceOverview
          ? balanceOverview
          : {
              ...defaultEstimatedTotalValue,
              totalInDOGE: 0,
              totalInUSD: 0,
              doginalsValue: 0,
              doginalsValueInDoge: 0,
              doginalsValueInUSD: 0,
              drc20sValue: 0,
              drc20sValueInDoge: 0,
              drc20sValueInUSD: 0,
              dunesValue: 0,
              dunesValueInDoge: 0,
              dunesValueInUSD: 0,
              safeBalance: 0,
              safeBalanceInDoge: 0,
              safeBalanceInUSD: 0,
            },
        isLoadingBalanceOverviewOfAddress,
        refetchBalanceOverview,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export { BalanceProvider, BalanceContext };
export type { Balance, BalanceResponse };
