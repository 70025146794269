import { useCallback, useEffect, useState, useRef } from "react";
import {
  DOGINALS_LISTINGS_PAGINATION_LIMIT,
  ONE_DOGE_IN_SHIBES,
} from "@/constants";
import { fetchDoginalListings } from "@/context/helpers/fetchDoginalListings.ts";
import {
  DoginalListing,
  FilterListingsCollection,
  SortListingsCollection,
  SortOrder,
  SortParamListingsCollection,
} from "@/types";
import { FetchDoginalListingsReturn } from "@/types/listings.ts";
import { ensureUniqueTableData, handleError } from "@/utility";

const NOOP = () => {};

export const FILTER_DEFAULT = {
  offset: 0,
  limit: DOGINALS_LISTINGS_PAGINATION_LIMIT,
  priceMin: undefined,
  priceMax: undefined,
  metadata: [],
  listed: undefined,
};

export const FILTER_TO_CONSIDER_FOR_ACTIVE_FILTERS = [
  "priceMin",
  "priceMax",
  "listed",
  "metadata",
  "listingStatus",
];

export const SORT_DEFAULT = {
  sortOrder: SortOrder.Ascending,
  sortParam: SortParamListingsCollection.Price,
};

/* fetches doginals of a certain collection AND address */

export const useFetchDoginalsOfCollection = (
  collectionSymbol?: string,
  address?: string,
): FetchDoginalListingsReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [doginals, setDoginals] = useState<DoginalListing[]>([]);
  const [floorPrice, setFloorPrice] = useState<null | number>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [useCacheBreaker, setUseCacheBreaker] = useState<boolean>(false);
  const [lastOffset, setLastOffset] = useState(0);
  const [sorting, setSorting] = useState<SortListingsCollection>(SORT_DEFAULT);
  const [filters, setFilters] = useState<FilterListingsCollection>({
    ...FILTER_DEFAULT,
  });

  // Cache to track ongoing fetch requests
  const ongoingRequests = useRef(new Set());

  const reset = useCallback(
    (
      filters?: FilterListingsCollection,
      sorting?: SortListingsCollection,
      cachebreaker: boolean = false,
    ) => {
      setDoginals([]);
      setTotalAmount(0);
      setFloorPrice(null);
      setLastOffset(0);
      setUseCacheBreaker(cachebreaker);
      setSorting({
        ...SORT_DEFAULT,
        ...(sorting ?? {}),
      });
      setFilters({
        ...FILTER_DEFAULT,
        ...(collectionSymbol && { collectionSymbol }),
        seller: address,
        ...(filters ?? {}),
      });
    },
    [address, collectionSymbol],
  );

  const setOffset = useCallback((offset: number) => {
    setFilters((prev) => ({ ...prev, offset }));
  }, []);

  const setSort = useCallback(
    (value: SortListingsCollection | undefined) => {
      // Always reset list when sorting changes
      setOffset(0);
      if (value === undefined) {
        setSorting(SORT_DEFAULT);
      } else {
        setSorting(value);
      }
    },
    [setOffset],
  );

  const fetch = useCallback(
    async (
      collectionSymbol: string,
      params: SortListingsCollection & FilterListingsCollection,
      address?: string,
      cachebreaker: boolean = false,
    ) => {
      const {
        offset,
        limit,
        seller,
        sortOrder,
        sortParam,
        listed,
        inscriptionId,
        priceMin,
        priceMax,
        metadata,
      } = params;

      const requestKey = JSON.stringify({ collectionSymbol, params, address });

      // Prevent duplicate request
      if (ongoingRequests.current.has(requestKey)) {
        console.log("Fetch prevented: Duplicate request");
        return;
      }

      // Add request to cache
      ongoingRequests.current.add(requestKey);

      try {
        const priceMinInShibes = priceMin
          ? (Number(priceMin) * ONE_DOGE_IN_SHIBES).toString()
          : undefined;
        const priceMaxInShibes = priceMax
          ? (Number(priceMax) * ONE_DOGE_IN_SHIBES).toString()
          : undefined;

        setIsLoading(true);
        const res = await fetchDoginalListings(
          {
            collectionSymbol: collectionSymbol.toLocaleLowerCase(),
            seller: seller ?? address ?? undefined,
            offset,
            limit,
            sortOrder,
            sortParam,
            listed,
            inscriptionId,
            priceMin: priceMinInShibes,
            priceMax: priceMaxInShibes,
            metadata,
            showWithEmptyOffers: listed ? false : undefined,
          },
          cachebreaker,
        );

        // this is important to ensure that data is resetted if the offset is 0 (for examle when sorting changes or filters change)
        if (
          offset != 0 &&
          offset > offset - DOGINALS_LISTINGS_PAGINATION_LIMIT
        ) {
          setDoginals(ensureUniqueTableData(res.doginals, "inscriptionId"));
        } else {
          setDoginals(res.doginals);
        }
        setTotalAmount(res.total);
        setFloorPrice(res.floorPrice);
        setHasMoreData(offset + limit < res.total);
        setLastOffset(offset);
      } catch (e: Error | unknown) {
        handleError(e);
        setDoginals([]);
        setHasMoreData(false);
        setFloorPrice(0);
        setTotalAmount(0);
        setLastOffset(0);
        setIsLoading(false);
        // we are throwing again to deal with error in UI further up the dependency chain
        throw new Error("Failed to fetch Doginal listings");
      } finally {
        setIsLoading(false);
        // Remove the request from the cache after it completes
        ongoingRequests.current.delete(requestKey);
      }
    },
    [],
  );

  const refetch = useCallback(
    async (collectionSymbol: string) => {
      if (collectionSymbol) {
        // @todo: check if we can remove cachebreaker
        // Need to check runJob to find pending balances, too.
        fetch(collectionSymbol, { ...filters, ...sorting }, address, false);
      }
    },
    [fetch, filters, sorting, address],
  );

  useEffect(() => {
    if (collectionSymbol) {
      fetch(
        collectionSymbol,
        { ...filters, ...sorting, seller: address },
        address,
        useCacheBreaker,
      );
    }
  }, [address, collectionSymbol, fetch, filters, sorting, useCacheBreaker]);

  return {
    refetch,
    data: doginals,
    isLoading: isLoading && doginals.length === 0,
    // not sure if 100% correct but should be fine for now
    isLoadingMoreData: isLoading && doginals.length > 0,
    hasMoreData,
    total: totalAmount,
    setFilters,
    setSort,
    offset: lastOffset,
    setOffset,
    sort: sorting,
    filters,
    reset,
    reset_for_symbol: NOOP,
    floorPrice,
  };
};
